// This file is generated by generate-pdf-transcript.ts
export class Pdfs {

public static BluffPointUniformPriceList2025 = {
  "path": "/assets/pdfs/2025/Bluff Point Uniform Price List 2025.pdf",
  "newWindow": true,
  "hints": [
    "Royal Blue Microfibre - Kids",
    "Royal Blue Microfibre - ",
    "Adults",
    "Royal Blue Fleece ",
    "Red/White Jacket ",
    "Cap Sleeve Dress   ",
    "Skort",
    "Shorts",
    "Polo Shirt",
    "Dress",
    "T",
    "rackpants",
    "Jacket",
    "Hats",
    "25.95",
    "19.95",
    "24.95",
    "26.95",
    "28.95",
    "44.95",
    "34.95",
    "39.95",
    "39.95",
    "29.95",
    "12.95",
    "Royal Blue Skort - Kids",
    "Royal Blue Skort - ",
    "Adults",
    "Royal Blue Shorts - Rugby Knit",
    "Royal Blue Shorts Microfibre",
    "Red/White Everyday Polo",
    "Red Bucket Hat ",
    "Uniform Price List - 2025",
    "Shoes",
    "Backpacks",
    "from $59.95",
    "from $39.95",
    "Sports Shoes  "
  ],
  "modified": "2025-01-23T08:27:10.635Z"
}

public static CBSHS2025111224 = {
  "path": "/assets/pdfs/2025/CBSHS 2025 111224.pdf",
  "newWindow": true,
  "hints": [
    " Uniform Price List 2025",
    "Middle School Polo Shirt - navy/teal/white with school crest",
    "Senior School Polo Shirt - navy/white/teal with school crest",
    "Shorts Microfibre - shorter length  -  navy blue with logo   ",
    "Shorts Microfibre - longer length  -  navy blue with logo   ",
    "Girls stretch polyester skort -  navy with CBSHS to leg",
    "Soft Shell Jacket - navy/teal/white with school crest",
    "Fleece Jumper   - navy blue with school crest ",
    "T",
    "rackpants - navy",
    "Bucket Hat - navy with CBSHS crest",
    "Senior Uniform Options",
    "White Shirt - Long Sleeve Boys with Logo",
    "                ",
    "    ",
    "White Blouse ",
    "Short Sleeve Girls with logo",
    "  ",
    "Boys Formal Shorts - Navy blue",
    "Girls Formal Short - Navy Blue",
    "Girls Cullotte - Navy Blue  ",
    "(Note:  made to order only)",
    "Long Pants - Grey  ",
    "(Note: Boys style only )",
    "T",
    "ie                 ",
    "$ 32.95 ",
    "$ 32.95",
    "$ 29.95",
    "$ 29.95",
    "$ 32.95",
    "$ 64.95",
    "$ 44.95",
    "$ 39.95",
    "$ 13.95",
    "$ 40.00",
    "$ 40.00",
    "$ 49.95",
    "$ 39.95",
    "$ 49.95",
    "$ 64.95  ",
    "$ 24.95",
    "CBSHSMSP",
    " ",
    "CBSHSSSP",
    "CBSHS7KSSN",
    "CBSHS7NSSN",
    "CBSHS7LPS(3)",
    "CBSHSJ",
    "CBSHS3FSV",
    "WTP3160(B)",
    "CBSHSBH",
    "CBSHS2006W",
    "CBSHS5038W",
    "GSSSPY/ GSSSPM",
    "FS021/ FS020",
    "CBSHSCUL",
    "TMPMY/TP2MM",
    "CBSHSTIE"
  ],
  "modified": "2025-01-23T08:27:10.635Z"
}

public static CRTAFECommercialCookeryPriceList2025 = {
  "path": "/assets/pdfs/2025/CRTAFE Commercial Cookery Price List 2025.pdf",
  "newWindow": true,
  "hints": [
    "Effective 25th January  2025",
    "Complete your Professional kit  by buying both  and  ",
    "Save 10% ",
    "1 x     25cm V",
    "ictorinox Chefs Knife        ",
    "1 x     30cm V",
    "ictorinox Sharpening Steel  ( stone acceptable)                       ",
    "1 x     V",
    "ictorinox Paring or T",
    "urning Knife                          ",
    "1 x     V",
    "ictorinox Horseshoe Peeler                                      ",
    "1 x     Plastic Bowl Scraper",
    "1 x     Knife W",
    "rap or T",
    "oolbox",
    "   ",
    "2 x     ",
    "YC Chef Jacket - Long or Short sleeve / Black or White  ",
    "from..",
    "1 x     JB Bib ",
    "Apron White or Black - Full Length",
    "1 x     JB Chef Hat - Black",
    "1 x     ‘Y",
    "es Chef",
    "’",
    " pant elastic waist - Black",
    "1 x     Satety Boot/Shoe closed in - prefer slip resistant ",
    " ",
    "     ",
    " ",
    "from..",
    "   ",
    "1 x     V",
    "ictorinox 15cm Boning Knife",
    "1 x     V",
    "ictorinox  18cm Filleting Knife     ",
    "1 x     V",
    "ictorinox 25cm Serrated Slicing Knife",
    "1 x     Heat Proof Spatula",
    "1 x     Balloon Whisk 35cm",
    "1 x     Piping Bag 46 cm",
    "1 x     Piping Nozzle - Star",
    "1 x     Piping Nozzle - Straight",
    "1 x     Pastry Brush 38mm",
    "1 x     Pair T",
    "ongs- Heavy duty Stainless",
    "1 x     Cranked Spatula - 25cm",
    "1 x     Thermometer - Probe type",
    "    ",
    "$ 89.95",
    "$ 54.95",
    "$  9.95",
    "$  8.95",
    "$  2.50",
    "$ 39.95",
    "$ 59.95",
    "$ 15.95",
    "$   9.95",
    "$  44.95",
    "$  89.95",
    "$ 44.95",
    "$ 54.95",
    "$ 74.95",
    "$ 29.95",
    "$ 16.95",
    "$ 10.95",
    "$ 16.95",
    "$ 16.95",
    "$   4.95",
    "$ 10.95",
    "$ 18.95",
    "$ 14.95",
    "5.2003.25",
    "7.8513",
    "5.3103",
    "7.6070",
    "2621024",
    "5KBBCA",
    "CH232ML/CH232MS",
    "5FCB",
    "5CCP",
    "CH234B",
    "5.6303",
    "5.3703.18",
    "5.4433.25",
    "2656168",
    "2674132",
    "2646306",
    "2646528",
    "2646531",
    "2646159",
    "2669069",
    "2660144",
    "12933",
    "‘The Essential’",
    " Kit T",
    "otal:             $ 21",
    "1.25",
    "Highly Recommeded ",
    "Additional Kit:             $345.40",
    "Commercial Cookery ",
    "Price List",
    "ESSENTIAL",
    " KNIVES and EQUIPMENT  ",
    "                                                 Product Code : CRTKIT1",
    "ESSENTIAL",
    " UNIFORM KIT",
    "Additional RECOMMENDED KNIVES and EQUIPMENT  ",
    "         Product Code : CRTKIT2  ",
    "2B Jensen Str",
    "eet",
    "Geraldton ",
    "W",
    "A",
    " 6530",
    "t: (08) 99653101",
    "e: customer",
    " service@totaluniforms.com.au"
  ],
  "modified": "2025-01-23T08:27:10.669Z"
}

public static GPS2025111224 = {
  "path": "/assets/pdfs/2025/GPS 2025   111224.pdf",
  "newWindow": true,
  "hints": [
    "Uniform Price List - 2025",
    "Microfibre lined trackpants",
    "Fleece T",
    "rackpant",
    "Microfibre Sports Jacket ",
    "Cap Sleeve Dress   ",
    "Skort",
    "Shorts",
    "Polo Shirt",
    "Dress",
    "T",
    "rackpants",
    "Jacket",
    "Hats",
    "Shoes",
    "Backpacks",
    "32.95",
    "32.95",
    "32.95",
    "44.95",
    "34.95",
    "57.50",
    "29.95",
    "15.95",
    "from $59.95",
    "from $39.95",
    "Fleece Zip Front W",
    "indcheater        ",
    "W",
    "indcheater",
    " ",
    "38.95",
    "Royal Pleated Skort",
    "Royal / Gold Microfibre Shorts",
    "Royal / Gold Polo",
    "Adjustable Bucket Hat ",
    "Sports Shoes  "
  ],
  "modified": "2025-01-23T08:27:10.670Z"
}

public static GSHS2025 = {
  "path": "/assets/pdfs/2025/GSHS 2025.pdf",
  "newWindow": true,
  "hints": [
    "Uniform Price List 2025",
    "Middle School Polo Shirt - navy/sky/white with school crest",
    "Note:  Middle School is ",
    "Y",
    "ear 7 to ",
    "Y",
    "ear 10",
    "Soft Shell Jacket - navy/sky with school crest - ",
    "New W",
    "armer Fabric!",
    "Short Microfibre - shorter length - navy with GSHS to leg ",
    "Short Microfibre - longer length - navy with GSHS to leg ",
    "T",
    "rackpants microfibre - navy blue with GSHS to leg",
    "Rugby Knit Jumper - navy blue with GSHS logo",
    "Faction Polo - ",
    "Atlas / Sirius / Mimosa / V",
    "ega",
    "Senior School Polo Shirt - navy/white/sky  with school crest",
    "Note:  Senior School is ",
    "Y",
    "ear 1",
    "1 and 12",
    "Boys  School long sleeve dress shirt - white  ",
    "Girls short sleeve blouse - white",
    "Boys formal pant - navy",
    "Girls formal pant - navy",
    "School tie - woven striped tie with school crest",
    "$ 32.95",
    "$ 69.95",
    "$ 29.95",
    "$ 29.95",
    "$ 39.95",
    "$ 49.95",
    "$ 39.95",
    "$ 39.95",
    "$ 32.95",
    "$ 32.95",
    "$ 32.95",
    "$ 54.95",
    " ",
    "$ 54.95",
    "$ 24.95",
    "GSHSMSP",
    " ",
    "GSHSJ",
    "GSHSSS",
    "GSHSSL",
    "GSHSTP",
    "GSHS3RNW",
    "GSHSFP",
    "GSHSFP",
    "GSHSSSP",
    "2006W",
    "5038W",
    "TRM024",
    "BTRF005",
    "GSHSTIE"
  ],
  "modified": "2025-01-23T08:27:10.670Z"
}

public static MTPPriceList2025251124 = {
  "path": "/assets/pdfs/2025/MTP Price List 2025 251124.pdf",
  "newWindow": true,
  "hints": [
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Faction polos in house colours - embr",
    "oider",
    "ed logo",
    "Y",
    "ouths sizes 4 - 14  and ",
    "Adult S - M ",
    "Micr",
    "ofibr",
    "e Sports Jacket ",
    "Y",
    "outh sizes 4 - 14  and ",
    "Adult S - M ",
    "Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant in bottle gr",
    "een ",
    "Y",
    "outh sizes 4-14  and ",
    "Adult S - M",
    "Adjustable Reversible Bucket hat ",
    "54-58cm  and  58-61cm ",
    "Assorted Sfida sports shoes                                                          ",
    "fr",
    "om...",
    "Gr",
    "eat range of Backpacks -  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "      ",
    "                 fr",
    "om...",
    "Polo - Faction",
    "Sports Short ",
    "Skort ",
    "Sports Jacket ",
    "T",
    "rackpant ",
    "Hats",
    "Sports Shoes",
    "Backpacks",
    "59.95",
    "39.95",
    "Polo shirts short sleeved  - with embr",
    "oider",
    "ed logo  ",
    "Y",
    "outh sizes   4 -16  and ",
    "Adult S - M             ",
    "Fleecy ",
    "W",
    "indcheater",
    "  - with embr",
    "oider",
    "ed logo  ",
    "        ",
    "Y",
    "outh sizes  4 -16 and ",
    "Adult  S -M    ",
    "Polo - Everyday",
    "W",
    "indcheater",
    "32.95",
    "34.95",
    "54.95",
    "26.95",
    "28.95",
    "Zip fr",
    "ont dr",
    "ess in school check fabric  4 - 18 ",
    "Dr",
    "ess",
    " ",
    "44.95",
    "15.95",
    " 44.95",
    " 34.95",
    "Micr",
    "ofibr",
    "e Sports Short   ",
    "Youth sizes 4 - 14 and ",
    "Adult size  S - M",
    "Str",
    "etch Skort  - Bottle Gr",
    "een",
    "Girls Sizes   4 - 14  ",
    "Uniform Price List 2025 ",
    "effective 25/1",
    "12/24"
  ],
  "modified": "2025-01-23T08:27:10.676Z"
}

public static NagleCollegePriceList2025 = {
  "path": "/assets/pdfs/2025/Nagle College Price List 2025.pdf",
  "newWindow": true,
  "hints": [
    "$  42.50",
    "$  64.95 ",
    "$  69.95 ",
    "$  64.95",
    "$  54.95",
    "$  64.95",
    "  ",
    "$  59.95",
    "  ",
    "$  42.50",
    "$  99.95",
    "$  39.95",
    "$  84.95",
    "$  12.50",
    "$  18.95",
    "$  39.95",
    "$ 109.95 ",
    "$  32.95",
    "$  32.95",
    "$ 109.95 ",
    "$  32.95",
    "Boys Sport Short",
    "Girls Sport Short",
    "$  44.95",
    "$  49.95",
    "Girls/Ladies Bathers",
    "Uniform Price List 2025",
    "24/09/2024",
    "Bucket Hat",
    "Girls Culotte"
  ],
  "modified": "2025-01-23T08:27:10.676Z"
}

public static SFXUniformPricelist2025251124 = {
  "path": "/assets/pdfs/2025/SFX Uniform Pricelist 2025 251124.pdf",
  "newWindow": true,
  "hints": [
    "St Francis Xavier",
    "St Francis Xavier",
    "Gold sports polo - ",
    "sizes 4-14 and S-M",
    "   ",
    "Micr",
    "ofibr",
    "e Sports Short  ",
    "- sizes youths 4-14 and adult S-M",
    "Black Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant ",
    " - sizes 4-14 and S-L",
    "Adjustable Hat -  ",
    "sizes",
    " ",
    "55-53 / 57-55 / 59-57 / 61-59",
    "Gr",
    "een Kindy Polo - ",
    "sizes 2-10",
    "   ",
    " ",
    " ",
    "Gr",
    "ey Boys Shirt -  ",
    "sizes  4 -18 ",
    "   ",
    " ",
    " ",
    "Girls ",
    "White Blouse - ",
    " sizes  Girls 4-16 and Ladies 8-12 ",
    "Boys Shorts - gr",
    "ey elastic waist -",
    " size 4-18 , S-XL",
    "Check Skirt - ",
    " sizes: Girls 4 -16 and Ladies 8 -12 ",
    "   ",
    " ",
    " ",
    "  ",
    " ",
    " ",
    "GirlsCullottes ",
    "- sizes 4-16 and ladies 8-12",
    "Sock - gr",
    "ey with school stripes ",
    "size 9-12, 13-3 and 2-8",
    "White Sock for",
    " Girls and Sport - ",
    "sizes 9-12, 13-3, 2-8",
    "Mycr",
    "on tr",
    "ouser",
    " - gr",
    "ey with elastic waist - ",
    "sizes 4-16 ",
    "Sports Polo ",
    "Sports Short ",
    "T",
    "rackpant ",
    "Hat ",
    "Kindy Polo",
    "Gr",
    "ey Shirt",
    "Blouse",
    "T",
    "r",
    "ousers",
    "Boys Socks",
    "Sport/ Girls Socks",
    "Shorts",
    "Skirt",
    "Cullotte",
    "35.95",
    "16.95",
    "39.95",
    "28.95",
    "39.95",
    "39.95",
    "34.95",
    "34.95",
    "39.95",
    "26.95",
    "49.95",
    "  12.95",
    "  12.95",
    " ",
    "Uniform Price List 2025",
    "updated 25/1",
    "1/24",
    "BOYS",
    "GIRLS",
    "KINDY",
    "SPOR",
    "TS UNIFORM",
    "Softshell Jacket    ",
    "sizes: 4/6 - 16 ",
    "                                     sizes: adult S- M ",
    "Jacket ",
    " ",
    "59.95",
    "64.95"
  ],
  "modified": "2025-01-23T08:27:10.679Z"
}

public static StLawrence2025111224 = {
  "path": "/assets/pdfs/2025/St Lawrence 2025 111224.pdf",
  "newWindow": true,
  "hints": [
    "Garment                                   Description                                     Sizes                    Price",
    "W",
    "orn three ( 3) days a week",
    "Polo Shirt  ",
    "Boys and Girls style",
    "T",
    "eal/Black/gold",
    "Socks ",
    "Unisex sock",
    "Black with teal hoop",
    "W",
    "orn everyday ",
    "Adjustable Bucket Hat - Black",
    "Adjustable Slouch Hat - Black",
    "Hat",
    "Shorts",
    "Stretch Microfibre Shorts",
    "Black",
    "Can be worn everyday",
    "Skort ",
    "Can be worn everyday ",
    "Pleated skort with inbuilt boy leg short ",
    "Gummer - Red",
    "Thomas - Blue",
    "Kelly - ",
    "Y",
    "ellow",
    "O’Collins - Green ",
    "W",
    "orn two (2) sport days a week",
    "Sport Polo Shirt",
    "Jacket",
    "Can be worn everyday",
    "Softshell Jacket",
    "Black with embroidery to front and back",
    "Kindy T",
    "Can be worn everyday",
    "Y",
    "ellow / Pink / Sky Blue / Charcoal",
    "S - L  adults",
    "4 - 14 childs",
    " ",
    "$ 32.95 ",
    "$ 29.95 ",
    "$ 39.95 ",
    "$ 39.95 ",
    "$ 59.95 ",
    "$ 64.95",
    "$ 23.00 ",
    "$ 15.95 ",
    "$ 16.95 ",
    "$ 12.95 ",
    "4 - 14 childs",
    "S - M  adults",
    "4 - 18 girls",
    "S - L  adults",
    "4 - 14 childs",
    "4/6 - 16 childs",
    "S - L  adults",
    "4 -  8  childs",
    "4 sizes  ",
    "4 sizes",
    "sizes:",
    "Childs 9 - 12 ",
    "Childs 13 -3",
    "Adult 8 -1",
    "1",
    "Adults 2 - 8 ",
    "Uniform Price List - 2025"
  ],
  "modified": "2025-01-23T08:27:10.684Z"
}

public static WaggrakinePrimary2025121124 = {
  "path": "/assets/pdfs/2025/Waggrakine Primary 2025 121124.pdf",
  "newWindow": true,
  "hints": [
    "Reversible Wide Brim Hat ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Royal/Red, Royal/Emerald, Royal/ Gold, Royal/Sky ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 19.95   ",
    " ",
    " ",
    "   BUCHA",
    "TREV",
    " ",
    "Jumpers",
    "Kindy / Pre- Primary Polo ",
    " ",
    " ",
    " ",
    "Childs sizes 3-8  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 34.95     ",
    "W",
    "AGGKP",
    " ",
    "Faction Polo Shirts",
    "Red /Green/ ",
    "Y",
    "ellow/ Blue ",
    " ",
    " ",
    " ",
    "Childs sizes 4-14 and ",
    "Adult S- L",
    "   ",
    " ",
    " ",
    "$ 26.95     ",
    "W",
    "AGGSP",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Royal Rugby Short ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Childs sizes  4-14 and ",
    "Adult S-M             $ 29.95     ",
    "5270ZSRB",
    "Hats",
    "Royal Blue Microfibre Short ",
    " ",
    "Childs sizes  6-14 and ",
    "Adult S-M ",
    " ",
    " ",
    " ",
    "$ 24.95     ",
    "SS29K/SS29",
    "Y",
    "ear 6 Polo ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Childs sizes 8-14 and adult M-L ",
    " ",
    " ",
    " ",
    "$ 34.95     ",
    "W",
    "AGG6P",
    "Shorts and Skorts",
    "Royal Blue Skort   ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Girls sizes 4-16 ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 28.95     ",
    "7LPS3",
    "Royal Blue Zip Windcheater ",
    " ",
    " Childs size 4-18 ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 38.95   ",
    " ",
    " ",
    "   ",
    " W",
    "AG2503RB",
    "Y",
    "ear 1-5 Polo ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Childs sizes 3-14 and adult S-M ",
    " ",
    " ",
    " ",
    "$ 34.95     ",
    "W",
    "AGG1-5P",
    " ",
    "Polo Shirts",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "effective 1/12/24",
    "Uniform Price List 2025",
    "Kindy/ Pre-Primary",
    " ",
    " ",
    "Y",
    "ear 1-5",
    " ",
    " ",
    "Y",
    "ear 6"
  ],
  "modified": "2025-01-23T08:27:10.686Z"
}

public static WandinaUniformCatalogue2025 = {
  "path": "/assets/pdfs/2025/Wandina Uniform Catalogue 2025.pdf",
  "newWindow": true,
  "hints": [
    "School Uniform 2025",
    "Garment                                   Description                                     Sizes                    Price",
    "Short sleeve cotton backed microfibre",
    "Aqua /navy/ teal with white piping",
    "W",
    "orn everyday except sport days",
    "Kindy / Pre-school / Primary School",
    "Day Polo Shirt  ",
    "Winter",
    " Jacket ",
    "100% Microfibre polyester  ",
    "Aqua /Navy/ teal/white",
    "Can wear everyday ",
    "Kindy / Pre-school / Primary School",
    "Fleece Jumper",
    " ",
    " Cotton / Polyester   - Navy Blue",
    "Can wear everyday ",
    "Kindy / Pre-school / Primary School",
    "Hat- reversible",
    "Navy one side sports house colour other side ",
    "100% Microfibre polyester",
    "Kindy / Pre-school / Primary School",
    "W",
    "orn everyday ",
    "JB 100% polyester sports shorts - Navy Blue",
    "Kindy / Pre-school / Primary School",
    "Shorts",
    "Kindy / Pre-school / Primary School",
    " Dress ",
    "Short sleeve zip front dress ",
    "Action back with rear belt",
    "Navy/white/teal with teal piping",
    "Can be worn everyday except sport days",
    "Kindy / Pre-school / Primary School",
    "Pleated skort with inbuilt boy leg short - Navy blue ",
    "Skort ",
    "Can be worn everyday with polo ",
    "* Optional for Kindy/Pre-primary at carnival",
    "Royal/Navy - Bobtail, Emerald/Navy- Frog",
    "Sport Polo Shirt",
    "Short sleeve cotton back microfibre",
    "Red/Navy-Echidna , Gold/Navy-Pelican",
    "W",
    "orn on sport days",
    "Primary School",
    "S - M  adults",
    "4 - 14 youths",
    " ",
    "$ 34.95 ",
    "$ 25.95 ",
    "$ 36.95",
    "$ 49.95 ",
    "$ 36.95 ",
    "$ 24.95 ",
    "$ 56.50 ",
    "$ 39.95 ",
    "4 - 14 youths",
    "S - M  adults",
    "4 - 14 girls",
    "4 - 14 youths",
    "S - M  adults",
    "L/XL",
    "   ( 61 - 57cm)",
    "S / M  ( 58 - 54cm ) ",
    "4 - 14 girls",
    "S - M  adults",
    "4 - 14 youths",
    "4 - 14 youths",
    "S - L  adults",
    "Ef",
    "fective 22/10/2024",
    "Please Note:",
    "Due to large price ",
    "rises on custom hats ",
    "this is product is ",
    "under review by the ",
    "school for 2025."
  ],
  "modified": "2025-01-23T08:27:10.687Z"
}

public static BinnuPrimaryPriceList2024 = {
  "path": "/assets/pdfs/Binnu Primary Price List 2024.pdf",
  "newWindow": true,
  "hints": [
    "BINNU PRIMAR",
    "Y",
    " SCHOOL",
    "Royal blue poly/cotton polo  ",
    "with embroidered school crest",
    "Style: BIN2KPRB",
    "Kids sizes:  2- 14",
    "Style: BIN210RB",
    "Adults sizes:  2XS - XL",
    "Royal blue zip front fleece jumper",
    "with embroidered school crest",
    "Style: BIN25037RB",
    "Kids sizes:  4 -16",
    "$ 26.95",
    "$ 28.95",
    "$ 38.95",
    "Uniform Pricelist 2024",
    "Parents please note",
    ":",
    "These are ",
    "not",
    " stock lines and need to me ordered ",
    "and paid for at the time of placing your order",
    ".",
    "Please ensure the correct size is ordered.  ",
    "W",
    "e are unable to exchange or refund purchases of this product. "
  ],
  "modified": "2025-01-23T08:27:10.695Z"
}

public static BluffPointUniformPriceList2021 = {
  "path": "/assets/pdfs/Bluff Point Uniform Price List 2021.pdf",
  "newWindow": true,
  "hints": [
    "34.95",
    "Bags and Backpacks",
    "Uniforms Price List 2021"
  ],
  "modified": "2025-01-23T08:27:10.698Z"
}

public static BluffPointUniformPriceList2023 = {
  "path": "/assets/pdfs/Bluff Point Uniform Price List 2023.pdf",
  "newWindow": true,
  "hints": [
    "Uniforms Price List 2023",
    "Micr",
    "ofibr",
    "e Sports Jacket ",
    "Y",
    "outh sizes 4 - 14  and ",
    "Adult S - M ",
    "Y",
    "outh sizes 4-14  and ",
    "Adult S - M",
    "Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant in Red",
    "54-58cm  and  58-61cm ",
    "Adjustable Reversible Bucket hat ",
    "Assorted Lotto sports shoes                                                          ",
    "fr",
    "om...",
    "Gr",
    "eat range of Backpacks  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "      ",
    "                 fr",
    "om...",
    "Sports Short ",
    "Skort ",
    "Sports Jacket ",
    "T",
    "rackpant ",
    "Hats",
    "Sports Shoes",
    "Backpacks",
    "48.50",
    "39.95",
    "Polo shirt  r",
    "ed/white - with embr",
    "oider",
    "ed logo  ",
    "Y",
    "outh sizes   4 -16  and ",
    "Adult S - M             ",
    "Polo Shirt",
    "25.95",
    "23.95",
    "28.95",
    "15.95",
    " 49.95",
    " 34.95",
    "Mesh Sports Short - Red  ",
    "Youth sizes 4 - 14 and ",
    "Adult size  S - M",
    "Str",
    "etch Skort  - Red    ",
    "Girls Size 4-16  ",
    "  "
  ],
  "modified": "2025-01-23T08:27:10.699Z"
}

public static BluffPointUniformPriceList2024 = {
  "path": "/assets/pdfs/Bluff Point Uniform Price List 2024.pdf",
  "newWindow": true,
  "hints": [
    "Uniforms Price List 2024",
    "Y",
    "outh sizes 4 - 14  and ",
    "Adult S - M ",
    "Red and ",
    "White Micr",
    "ofibr",
    "e Sports Jacket ",
    "Red Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant ",
    "Y",
    "outh sizes 4-14  and ",
    "Adult S - M",
    "Red Bucket Hat ",
    " M/L",
    " and L/XL",
    "Assorted  sports shoes                                                          ",
    "fr",
    "om...",
    "Gr",
    "eat range of Backpacks  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "      ",
    "                 fr",
    "om...",
    "Sports Short ",
    "Skort ",
    "Sports Jacket ",
    "T",
    "rackpant ",
    "Hats",
    "Sports Shoes",
    "Backpacks",
    "48.50",
    "39.95",
    "Red and ",
    "White Polo Shirt - with embr",
    "oider",
    "ed logo  ",
    "Y",
    "outh sizes   4 -16",
    "Adult sizes    S - M             ",
    "Polo Shirt",
    "25.95",
    "27.95",
    "24.95",
    "29.95",
    "15.95",
    " 49.95",
    " 39.95",
    "Red Sports Mesh Short ",
    "Youth sizes 4 - 14  and  ",
    "Adult size  S - M",
    "Red Str",
    "etch Skort    ",
    "Girls Size 4-16  ",
    "  "
  ],
  "modified": "2025-01-23T08:27:10.703Z"
}

public static CBSHS2022011122 = {
  "path": "/assets/pdfs/CBSHS 2022 011122.pdf",
  "newWindow": true,
  "hints": [
    " Uniform Price List 2023",
    "effective 1/1",
    "1/2022",
    "Middle School Polo Shirt - navy/teal/white with school crest",
    "Senior School Polo Shirt - navy/white/teal with school crest",
    "Shorts Microfibre - shorter length  -  navy blue with logo   ",
    "Shorts Microfibre - longer length  -  navy blue with logo   ",
    "Girls stretch polyester skort -  navy with CBSHS to leg",
    "Soft Shell Jacket - navy/teal/white with school crest",
    "Fleece Jumper   - navy blue with school crest ",
    "T",
    "rackpants - navy",
    "Bucket Hat - navy with CBSHS crest",
    "Senior Uniform Options",
    "White Shirt - Long Sleeve Boys with Logo",
    "                ",
    "    ",
    "White Blouse ",
    "Short Sleeve Girls with logo",
    "  ",
    "Boys Formal Shorts - Navy blue",
    "Girls Formal Short - Navy Blue",
    "Girls Cullotte - Navy Blue  ",
    "(Note:  made to order only)",
    "Long Pants - Grey  ",
    "(Note: Boys style only )",
    "T",
    "ie                 ",
    "$ 32.95 ",
    "$ 32.95",
    "$ 29.95",
    "$ 29.95",
    "$ 32.95",
    "$ 64.95",
    "$ 44.95",
    "$ 39.95",
    "$ 13.95",
    "$ 40.00",
    "$ 40.00",
    "$ 49.95",
    "$ 39.95",
    "$ 49.95",
    "$ 59.95  ",
    "$ 24.95",
    "CBSHSMSP",
    " ",
    "CBSHSSSP",
    "CBSHS7KSSN",
    "CBSHS7NSSN",
    "CBSHS7LPS(3)",
    "CBSHSJ",
    "CBSHS3FSV",
    "WTP3160(B)",
    "CBSHSBH",
    "CBSHS2006W",
    "CBSHS5038W",
    "GSSSPY/ GSSSPM",
    "FS021/ FS020",
    "CBSHSCUL",
    "TMPMY/TP2MM",
    "CBSHSTIE"
  ],
  "modified": "2025-01-23T08:27:10.705Z"
}

public static CBSHS2024011223 = {
  "path": "/assets/pdfs/CBSHS 2024 011223.pdf",
  "newWindow": true,
  "hints": [
    " Uniform Price List 2024",
    "effective 1/12/2023",
    "Middle School Polo Shirt - navy/teal/white with school crest",
    "Senior School Polo Shirt - navy/white/teal with school crest",
    "Shorts Microfibre - shorter length  -  navy blue with logo   ",
    "Shorts Microfibre - longer length  -  navy blue with logo   ",
    "Girls stretch polyester skort -  navy with CBSHS to leg",
    "Soft Shell Jacket - navy/teal/white with school crest",
    "Fleece Jumper   - navy blue with school crest ",
    "T",
    "rackpants - navy",
    "Bucket Hat - navy with CBSHS crest",
    "Senior Uniform Options",
    "White Shirt - Long Sleeve Boys with Logo",
    "                ",
    "    ",
    "White Blouse ",
    "Short Sleeve Girls with logo",
    "  ",
    "Boys Formal Shorts - Navy blue",
    "Girls Formal Short - Navy Blue",
    "Girls Cullotte - Navy Blue  ",
    "(Note:  made to order only)",
    "Long Pants - Grey  ",
    "(Note: Boys style only )",
    "T",
    "ie                 ",
    "$ 32.95 ",
    "$ 32.95",
    "$ 29.95",
    "$ 29.95",
    "$ 32.95",
    "$ 64.95",
    "$ 44.95",
    "$ 39.95",
    "$ 13.95",
    "$ 40.00",
    "$ 40.00",
    "$ 49.95",
    "$ 39.95",
    "$ 49.95",
    "$ 64.95  ",
    "$ 24.95",
    "CBSHSMSP",
    " ",
    "CBSHSSSP",
    "CBSHS7KSSN",
    "CBSHS7NSSN",
    "CBSHS7LPS(3)",
    "CBSHSJ",
    "CBSHS3FSV",
    "WTP3160(B)",
    "CBSHSBH",
    "CBSHS2006W",
    "CBSHS5038W",
    "GSSSPY/ GSSSPM",
    "FS021/ FS020",
    "CBSHSCUL",
    "TMPMY/TP2MM",
    "CBSHSTIE"
  ],
  "modified": "2025-01-23T08:27:10.708Z"
}

public static CRTAFECommercialCookeryPriceListrevisedfor2023 = {
  "path": "/assets/pdfs/CRTAFE Commercial Cookery Price List revised for 2023.pdf",
  "newWindow": true,
  "hints": [
    "email: ",
    "admin@totaluniforms.com.au",
    "Effective 12th December 2022",
    "Buy ‘THE ESSENTIAL and ‘RECOMMENDED’ Knife Kits together ",
    "and ",
    "SA",
    "VE OVER $ 50.00  ",
    "1 x     25cm V",
    "ictorinox Chefs Knife        ",
    "1 x     30cm V",
    "ictorinox Sharpening Steel  ( stone acceptable)                       ",
    "1 x     V",
    "ictorinox Paring or T",
    "urning Knife                          ",
    "1 x     V",
    "ictorinox Horseshoe Peeler                                      ",
    "1 x     Plastic Bowl Scraper",
    "1 x     Knife W",
    "rap or T",
    "oolbox",
    "   ",
    "2 x     Chef Jacket White - long or short sleeve        ",
    "1 x     Bib ",
    "Apron White - Full Length",
    "1 x     Chef Hat - black",
    "1 x     Chef pant elastic waist - black",
    "1 x     Satety Boot/Shoe closed in - prefer slip resistant ",
    " ",
    "  $  89.00 ",
    " from..",
    "   ",
    "1 x     V",
    "ictorinox 15cm Boning Knife",
    "1 x     V",
    "ictorinox  18cm Filleting Knife     ",
    "1 x     V",
    "ictorinox 25cm Serrated Slicing Knife",
    "1 x     Heat Proof Spatula",
    "1 x     Balloon Whisk 35cm",
    "1 x     Piping Bag 46 cm",
    "1 x     Piping Nozzle - Star",
    "1 x     Piping Nozzle - Straight",
    "1 x     Pastry Brush 38mm",
    "1 x     Pair T",
    "ongs- Heavy duty Stainless",
    "1 x     Cranked Spatula - 25cm",
    "1 x     Thermometer - Probe type",
    "    ",
    "$ 79.95",
    "$ 54.95",
    "$  9.95",
    "$  8.95",
    "$  2.50",
    "$ 39.95",
    "$ 49.95",
    "$ 15.95",
    "$   9.95",
    "$  39.95",
    "$ 44.95",
    "$ 54.95",
    "$ 74.95",
    "$ 29.95",
    "$ 16.95",
    "$ 10.95",
    "$ 16.95",
    "$ 16.95",
    "$   4.95",
    "$ 10.95",
    "$ 18.95",
    "$ 14.95",
    "5.2003.25",
    "7.8513",
    "5.31003",
    "7.6070",
    "2621024",
    "5KB",
    "55CJ",
    "5A",
    "WBI",
    "5FCB",
    "5CCP",
    "5.6303",
    "5.3703.18",
    "5.4433.25",
    "2656168",
    "2674132",
    "2646306",
    "2646258",
    "2646531",
    "2646159",
    "2669069",
    "2660144",
    "12933",
    "‘The Essential’",
    " Kit T",
    "otal:             $ 196.25",
    "Highly Recommeded ",
    "Additional Kit:             $316.40",
    "Combined Total Cost  ",
    "$ 5",
    "12.65  only $ 460.00",
    "Commercial Cookery ",
    "Price List",
    "ESSENTIAL",
    " KNIVES and EQUIPMENT",
    "ESSENTIAL",
    " UNIFORM KIT",
    "Additional RECOMMENDED KNIVES and EQUIPMENT"
  ],
  "modified": "2025-01-23T08:27:10.713Z"
}

public static GPSAugust2021 = {
  "path": "/assets/pdfs/GPS August 2021.pdf",
  "newWindow": true,
  "hints": [],
  "modified": "2025-01-23T08:27:10.718Z"
}

public static GPSDecember2023 = {
  "path": "/assets/pdfs/GPS December 2023.pdf",
  "newWindow": true,
  "hints": [
    "Uniform Price List - December",
    " 2024",
    "Y",
    "outh sizes 4-14 and ",
    "Adult sizes S-M",
    "Gold polo with striped collar",
    "Girls sizes 4-14 ",
    "Girls Pleated Skort -  r",
    "oyal blue      ",
    "Sports Shorts Micr",
    "ofibr",
    "e  - Royal Blue/Gold",
    "Y",
    "outh sizes 4-14 and ",
    "Adult sizes S-M",
    "Micr",
    "ofibr",
    "e lined trackpants",
    "Y",
    "outh sizes 6-14 and Adult sizes S-M",
    "*NEW",
    " STYLE for",
    " 2023",
    "/2024 ",
    "Micr",
    "ofibr",
    "e ",
    "T",
    "rack Jacket with school cr",
    "est",
    "Y",
    "outh sizes 3-16   and Adult sizes S-M",
    "W",
    "ide Brim with embr",
    "oider",
    "ed logo - Royal / Blue",
    "55cm, 57cm, 59cm 61cm",
    "Bucket hat adjustable- Royal Blue",
    "M/L",
    " and L/XL",
    "Cap Sleeve summer",
    " check dr",
    "ess   ",
    "Girls sizes 4-12",
    "Polo Shirts",
    "Skort",
    "Dr",
    "esses",
    "T",
    "rackpants",
    "T",
    "rackpants",
    "Jacket",
    "Hats/Caps",
    "Shorts",
    "32.95",
    "29.95",
    "32.95",
    "44.95",
    "32.95",
    "57.50",
    "15.95",
    "15.95",
    "Sports  Shoes - assorted styles                                  ",
    "...fr",
    "om",
    "Assorted Backpacks                                                 ",
    " ...fr",
    "om",
    "Shoes",
    "Backpacks",
    " ",
    "   34.95",
    "  44.95",
    "Fleece Zip Fr",
    "ont ",
    "W",
    "indcheater",
    "        ",
    "sizes  4-16 youth",
    "Fleece Jumper",
    " ",
    "38.50"
  ],
  "modified": "2025-01-23T08:27:10.723Z"
}

public static GPSNovember2023 = {
  "path": "/assets/pdfs/GPS November 2023.pdf",
  "newWindow": true,
  "hints": [
    "Uniform Price List - October",
    " 2023",
    "Y",
    "outh sizes 4-14 and ",
    "Adult sizes S-M",
    "Gold polo with striped collar",
    "Girls Pleated Skort -  r",
    "oyal blue      ",
    "Girls sizes 4-14 ",
    "Sports Shorts Micr",
    "ofibr",
    "e  - Royal Blue/Gold",
    "Y",
    "outh sizes 4-14 and ",
    "Adult sizes S-M",
    "Micr",
    "ofibr",
    "e lined trackpants",
    "Y",
    "outh sizes 6-14 and Adult sizes S-M",
    "Micr",
    "ofibr",
    "e ",
    "T",
    "rack Jacket with school cr",
    "est",
    "*NEW",
    " STYLE for",
    " 2023",
    "/2024 ",
    "Y",
    "outh sizes 3-16   and Adult sizes S-M",
    "M/L",
    " and L/XL",
    "W",
    "ide Brim with embr",
    "oider",
    "ed logo - Royal / Blue",
    "55cm, 57cm, 59cm 61cm",
    "Bucket hat adjustable- Royal Blue",
    "Cap Sleeve summer",
    " check dr",
    "ess   ",
    "Girls sizes 4-12",
    "Polo Shirts",
    "Skort",
    "Dr",
    "esses",
    "T",
    "rackpants",
    "T",
    "rackpants",
    "Jacket",
    "Hats/Caps",
    "Shorts",
    "32.95",
    "29.95",
    "32.95",
    "39.95",
    "32.95",
    "57.50",
    "15.95",
    "15.95",
    "Sports  Shoes - assorted styles                                  ",
    "...fr",
    "om",
    "Assorted Backpacks                                                 ",
    " ...fr",
    "om",
    "Shoes",
    "Backpacks",
    " ",
    "   34.95",
    "  44.95",
    "Fleece Zip Fr",
    "ont ",
    "W",
    "indcheater",
    "        ",
    "sizes  4-16 youth",
    "Fleece Jumper",
    " ",
    "38.50"
  ],
  "modified": "2025-01-23T08:27:10.728Z"
}

public static GSHS2022010722 = {
  "path": "/assets/pdfs/GSHS 2022 010722.pdf",
  "newWindow": true,
  "hints": [
    "Uniform Price List 2022",
    "effective 1/7/22",
    "Middle School Polo Shirt - navy/sky/white with school crest",
    "Note:  Middle School is ",
    "Y",
    "ear 7 to ",
    "Y",
    "ear 10",
    "Soft Shell Jacket - navy/sky with school crest",
    "Short Microfibre - shorter length - navy with GSHS to leg ",
    "Short Microfibre - longer length - navy with GSHS to leg ",
    "T",
    "rackpants microfibre - navy blue with GSHS to leg",
    "Rugby Knit Jumper - navy blue with GSHS logo",
    "Senior School Polo Shirt - navy/white/sky  with school crest",
    "Note:  Senior School is ",
    "Y",
    "ear 1",
    "1 and 12",
    "Boys  School long sleeve dress shirt - white  *",
    "Girls short sleeve blouse - white *",
    "Boys formal pant - navy",
    "Girls formal pant - navy",
    "School tie - woven striped tie with school crest",
    "                 ",
    "$ 32.95",
    "$ 59.95",
    "$ 29.95",
    "$ 29.95",
    "$ 39.95",
    "$ 40.00",
    "$ 32.95",
    "$ 32.95",
    "$ 32.95",
    "$ 54.95",
    " ",
    "$ 54.95",
    "$ 24.95",
    "GSHSMSP",
    " ",
    "GSHSJ",
    "GSHSSS",
    "GSHSSL",
    "GSHSTP",
    "GSHS3RNW",
    "GSHSSSP",
    "2006W",
    "5038W",
    "TRM024",
    "BTRF005",
    "GSHSTIE"
  ],
  "modified": "2025-01-23T08:27:10.729Z"
}

public static GSHS2022010822 = {
  "path": "/assets/pdfs/GSHS 2022 010822.pdf",
  "newWindow": true,
  "hints": [
    "Uniform Price List 2022",
    "effective 1/8/22",
    "Middle School Polo Shirt - navy/sky/white with school crest",
    "Note:  Middle School is ",
    "Y",
    "ear 7 to ",
    "Y",
    "ear 10",
    "Soft Shell Jacket - navy/sky with school crest",
    "Short Microfibre - shorter length - navy with GSHS to leg ",
    "Short Microfibre - longer length - navy with GSHS to leg ",
    "T",
    "rackpants microfibre - navy blue with GSHS to leg",
    "Rugby Knit Jumper - navy blue with GSHS logo",
    "Senior School Polo Shirt - navy/white/sky  with school crest",
    "Note:  Senior School is ",
    "Y",
    "ear 1",
    "1 and 12",
    "Boys  School long sleeve dress shirt - white  *",
    "Girls short sleeve blouse - white *",
    "Boys formal pant - navy",
    "Girls formal pant - navy",
    "School tie - woven striped tie with school crest",
    "                 ",
    "$ 32.95",
    "$ 59.95",
    "$ 29.95",
    "$ 29.95",
    "$ 39.95",
    "$ 44.95",
    "$ 32.95",
    "$ 32.95",
    "$ 32.95",
    "$ 54.95",
    " ",
    "$ 54.95",
    "$ 24.95",
    "GSHSMSP",
    " ",
    "GSHSJ",
    "GSHSSS",
    "GSHSSL",
    "GSHSTP",
    "GSHS3RNW",
    "GSHSSSP",
    "2006W",
    "5038W",
    "TRM024",
    "BTRF005",
    "GSHSTIE"
  ],
  "modified": "2025-01-23T08:27:10.729Z"
}

public static GSHS2024010124V2 = {
  "path": "/assets/pdfs/GSHS 2024 010124 V2.pdf",
  "newWindow": true,
  "hints": [
    "Uniform Price List 2024",
    "Effective 1st January 2024",
    "Middle School Polo Shirt - navy/sky/white with school crest",
    "Note:  Middle School is ",
    "Y",
    "ear 7 to ",
    "Y",
    "ear 10",
    "Soft Shell Jacket - navy/sky with school crest",
    "Short Microfibre - shorter length - navy with GSHS to leg ",
    "Short Microfibre - longer length - navy with GSHS to leg ",
    "T",
    "rackpants microfibre - navy blue with GSHS to leg",
    "Rugby Knit Jumper - navy blue with GSHS logo",
    "NEW !",
    " Faction Polo - ",
    "Atlas / Sirius / Mimosa / V",
    "ega",
    "Senior School Polo Shirt - navy/white/sky  with school crest",
    "Note:  Senior School is ",
    "Y",
    "ear 1",
    "1 and 12",
    "Boys  School long sleeve dress shirt - white  ",
    "Girls short sleeve blouse - white",
    "Boys formal pant - navy",
    "Girls formal pant - navy",
    "School tie - woven striped tie with school crest",
    "$ 32.95",
    "$ 59.95",
    "$ 29.95",
    "$ 29.95",
    "$ 39.95",
    "$ 44.95",
    "$ 39.95",
    "$ 39.95",
    "$ 32.95",
    "$ 32.95",
    "$ 32.95",
    "$ 54.95",
    " ",
    "$ 54.95",
    "$ 24.95",
    "GSHSMSP",
    " ",
    "GSHSJ",
    "GSHSSS",
    "GSHSSL",
    "GSHSTP",
    "GSHS3RNW",
    "GSHSFP",
    "GSHSFP",
    "GSHSSSP",
    "2006W",
    "5038W",
    "TRM024",
    "BTRF005",
    "GSHSTIE",
    "email: ",
    "admin@totaluniforms.com.au"
  ],
  "modified": "2025-01-23T08:27:10.739Z"
}

public static MTPPriceList0110222023 = {
  "path": "/assets/pdfs/MTP Price List 011022 2023.pdf",
  "newWindow": true,
  "hints": [
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Y",
    "ouths sizes 4 - 14  and ",
    "Adult S - M ",
    "Faction polos in house colours - embr",
    "oider",
    "ed logo",
    "Micr",
    "ofibr",
    "e Sports Jacket ",
    "Y",
    "outh sizes 4 - 14  and ",
    "Adult S - M ",
    "Y",
    "outh sizes 4-14  and ",
    "Adult S - M",
    "Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant in bottle gr",
    "een ",
    "54-58cm  and  58-61cm ",
    "Adjustable Reversible Bucket hat ",
    "Assorted Lotto sports shoes                                                          ",
    "fr",
    "om...",
    "Gr",
    "eat range of Backpacks -  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "      ",
    "                 fr",
    "om...",
    "Polo - Faction",
    "Sports Short ",
    "Skort ",
    "Sports Jacket ",
    "T",
    "rackpant ",
    "Hats",
    "Sports Shoes",
    "Backpacks",
    "48.50",
    "39.95",
    "Y",
    "outh sizes   4 -16  and ",
    "Adult S - M             ",
    "Polo shirts short sleeved  - with embr",
    "oider",
    "ed logo  ",
    "Fleecy ",
    "W",
    "indcheater",
    "  - with embr",
    "oider",
    "ed logo  ",
    "        ",
    "Y",
    "outh sizes  4 -16 and ",
    "Adult  S -M    ",
    "Polo - Everyday",
    "W",
    "indcheater",
    "32.95",
    "32.95",
    "57.50",
    "25.95",
    "28.95",
    "28.95",
    "Zip fr",
    "ont dr",
    "ess in school check fabric  4 - 18 ",
    "Dr",
    "ess",
    " ",
    "49.95",
    "15.95",
    " 44.95",
    " 34.95",
    "Micr",
    "ofibr",
    "e Sports Short   ",
    "Youth sizes 4 - 14 and ",
    "Adult size  S - M",
    "Str",
    "etch Skort  - Bottle Gr",
    "een",
    "Girls Sizes   4 - 14 ",
    "Ladies sizes  8 - 14",
    "Uniform Price List ",
    "ef",
    "fective 1st October 2022"
  ],
  "modified": "2025-01-23T08:27:10.741Z"
}

public static MTPPriceList1512222023 = {
  "path": "/assets/pdfs/MTP Price List 151222 2023.pdf",
  "newWindow": true,
  "hints": [
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Y",
    "ouths sizes 4 - 14  and ",
    "Adult S - M ",
    "Faction polos in house colours - embr",
    "oider",
    "ed logo",
    "Micr",
    "ofibr",
    "e Sports Jacket ",
    "Y",
    "outh sizes 4 - 14  and ",
    "Adult S - M ",
    "Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant in bottle gr",
    "een ",
    "Y",
    "outh sizes 4-14  and ",
    "Adult S - M",
    "54-58cm  and  58-61cm ",
    "Adjustable Reversible Bucket hat ",
    "Assorted Lotto sports shoes                                                          ",
    "fr",
    "om...",
    "Gr",
    "eat range of Backpacks -  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "      ",
    "                 fr",
    "om...",
    "Polo - Faction",
    "Sports Short ",
    "Skort ",
    "Sports Jacket ",
    "T",
    "rackpant ",
    "Hats",
    "Sports Shoes",
    "Backpacks",
    "49.95",
    "39.95",
    "Polo shirts short sleeved  - with embr",
    "oider",
    "ed logo  ",
    "Y",
    "outh sizes   4 -16  and ",
    "Adult S - M             ",
    "        ",
    "Y",
    "outh sizes  4 -16 and ",
    "Adult  S -M    ",
    "Fleecy ",
    "W",
    "indcheater",
    "  - with embr",
    "oider",
    "ed logo  ",
    "Polo - Everyday",
    "W",
    "indcheater",
    "32.95",
    "32.95",
    "57.50",
    "25.95",
    "28.95",
    "28.95",
    "Zip fr",
    "ont dr",
    "ess in school check fabric  4 - 18 ",
    "Dr",
    "ess",
    " ",
    "49.95",
    "15.95",
    " 44.95",
    " 34.95",
    "Micr",
    "ofibr",
    "e Sports Short   ",
    "Youth sizes 4 - 14 and ",
    "Adult size  S - M",
    "Str",
    "etch Skort  - Bottle Gr",
    "een",
    "Girls Sizes   4 - 14 ",
    "Ladies sizes  8 - 14",
    "Uniform Price List ",
    "effective 15th December",
    " 2022"
  ],
  "modified": "2025-01-23T08:27:10.742Z"
}

public static MTPPriceList2024141223v2 = {
  "path": "/assets/pdfs/MTP Price List 2024 141223(v2).pdf",
  "newWindow": true,
  "hints": [
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Y",
    "ouths sizes 4 - 14  and ",
    "Adult S - M ",
    "Faction polos in house colours - embr",
    "oider",
    "ed logo",
    "Micr",
    "ofibr",
    "e Sports Jacket ",
    "Y",
    "outh sizes 4 - 14  and ",
    "Adult S - M ",
    "Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant in bottle gr",
    "een ",
    "Y",
    "outh sizes 4-14  and ",
    "Adult S - M",
    "Adjustable Reversible Bucket hat ",
    "54-58cm  and  58-61cm ",
    "Assorted Lotto sports shoes                                                          ",
    "fr",
    "om...",
    "Gr",
    "eat range of Backpacks -  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "      ",
    "                 fr",
    "om...",
    "Polo - Faction",
    "Sports Short ",
    "Skort ",
    "Sports Jacket ",
    "T",
    "rackpant ",
    "Hats",
    "Sports Shoes",
    "Backpacks",
    "57.50",
    "39.95",
    "Y",
    "outh sizes   4 -16  and ",
    "Adult S - M             ",
    "Polo shirts short sleeved  - with embr",
    "oider",
    "ed logo  ",
    "Fleecy ",
    "W",
    "indcheater",
    "  - with embr",
    "oider",
    "ed logo  ",
    "        ",
    "Y",
    "outh sizes  4 -16 and ",
    "Adult  S -M    ",
    "Polo - Everyday",
    "W",
    "indcheater",
    "32.95",
    "34.95",
    "54.95",
    "26.95",
    "28.95",
    "Zip fr",
    "ont dr",
    "ess in school check fabric  4 - 18 ",
    "Dr",
    "ess",
    " ",
    "44.95",
    "15.95",
    " 44.95",
    " 34.95",
    "Micr",
    "ofibr",
    "e Sports Short   ",
    "Youth sizes 4 - 14 and ",
    "Adult size  S - M",
    "Str",
    "etch Skort  - Bottle Gr",
    "een",
    "Girls Sizes   4 - 14  ",
    "Uniform Price List 2024 ",
    "effective 1/12/23"
  ],
  "modified": "2025-01-23T08:27:10.747Z"
}

public static MTPPriceList2024141223 = {
  "path": "/assets/pdfs/MTP Price List 2024 141223.pdf",
  "newWindow": true,
  "hints": [
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Mt ",
    "T",
    "ar",
    "coola Primary",
    "Faction polos in house colours - embr",
    "oider",
    "ed logo",
    "Y",
    "ouths sizes 4 - 14  and ",
    "Adult S - M ",
    "Y",
    "outh sizes 4 - 14  and ",
    "Adult S - M ",
    "Micr",
    "ofibr",
    "e Sports Jacket ",
    "Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant in bottle gr",
    "een ",
    "Y",
    "outh sizes 4-14  and ",
    "Adult S - M",
    "54-58cm  and  58-61cm ",
    "Adjustable Reversible Bucket hat ",
    "Assorted Lotto sports shoes                                                          ",
    "fr",
    "om...",
    "Gr",
    "eat range of Backpacks -  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "      ",
    "                 fr",
    "om...",
    "Polo - Faction",
    "Sports Short ",
    "Skort ",
    "Sports Jacket ",
    "T",
    "rackpant ",
    "Hats",
    "Sports Shoes",
    "Backpacks",
    "57.50",
    "39.95",
    "Polo shirts short sleeved  - with embr",
    "oider",
    "ed logo  ",
    "Y",
    "outh sizes   4 -16  and ",
    "Adult S - M             ",
    "Fleecy ",
    "W",
    "indcheater",
    "  - with embr",
    "oider",
    "ed logo  ",
    "        ",
    "Y",
    "outh sizes  4 -16 and ",
    "Adult  S -M    ",
    "Polo - Everyday",
    "W",
    "indcheater",
    "32.95",
    "34.95",
    "54.95",
    "26.95",
    "28.95",
    "Zip fr",
    "ont dr",
    "ess in school check fabric  4 - 18 ",
    "Dr",
    "ess",
    " ",
    "49.95",
    "15.95",
    " 44.95",
    " 34.95",
    "Micr",
    "ofibr",
    "e Sports Short   ",
    "Youth sizes 4 - 14 and ",
    "Adult size  S - M",
    "Str",
    "etch Skort  - Bottle Gr",
    "een",
    "Girls Sizes   4 - 14  ",
    "Uniform Price List ",
    "effective 1/12/23"
  ],
  "modified": "2025-01-23T08:27:10.749Z"
}

public static NagleCollegePriceList2023 = {
  "path": "/assets/pdfs/Nagle College Price List 2023.pdf",
  "newWindow": true,
  "hints": [
    "$ 79.95",
    "$ 46.50",
    "$ 46.50",
    "Boys Sport Short",
    "Girls Sport Short",
    "$ 18.95",
    "$ 52.50",
    "$ 64.95",
    "$ 59.95",
    "Girls/Ladies Bathers",
    "Uniform Price List 2023",
    "$59.95",
    "19/1",
    "1/2022",
    "Black Leather Shoes ",
    "                from",
    "Bucket Hat"
  ],
  "modified": "2025-01-23T08:27:10.749Z"
}

public static NagleCollegePriceList2024Noone = {
  "path": "/assets/pdfs/Nagle College Price List 2024 ( Noone ).pdf",
  "newWindow": true,
  "hints": [
    "$  49.50",
    "$  94.95",
    "$  98.50",
    "$  64.95",
    "$  79.95",
    "$  49.50",
    "$ 129.95",
    "$  39.95",
    "$ 104.95",
    "$  14.50",
    "$  19.95",
    "$  39.95",
    "$ 109.95",
    "$  32.95",
    "$  32.95",
    "$ 109.95",
    "$  32.95",
    "Boys Sport Short",
    "Girls Sport Short",
    "$  54.95",
    "$  64.95",
    "Girls/Ladies Bathers",
    "Proposed Uniform Price List 2024",
    "( Noone Product )",
    "$  59.95",
    "22/07/2023",
    "Bucket Hat"
  ],
  "modified": "2025-01-23T08:27:10.759Z"
}

public static NagleCollegePriceList2024 = {
  "path": "/assets/pdfs/Nagle College Price List 2024.pdf",
  "newWindow": true,
  "hints": [
    "$  49.50",
    "$  94.95",
    "$  98.50",
    "$  64.95",
    "$  79.95",
    "$  49.50",
    "$ 129.95",
    "$  39.95",
    "$ 104.95",
    "$  14.50",
    "$  19.95",
    "$  39.95",
    "$ 109.95",
    "$  32.95",
    "$  32.95",
    "$ 109.95",
    "$  32.95",
    "Boys Sport Short",
    "Girls Sport Short",
    "$  54.95",
    "$  64.95",
    "Girls/Ladies Bathers",
    "Uniform Price List 2024",
    "$  59.95",
    "1/12/2023",
    "Bucket Hat"
  ],
  "modified": "2025-01-23T08:27:10.768Z"
}

public static SFXUniformPricelist2023011122 = {
  "path": "/assets/pdfs/SFX Uniform Pricelist 2023 011122.pdf",
  "newWindow": true,
  "hints": [
    "St Francis Xavier",
    "St Francis Xavier",
    "New full Dye Sublimated Polo",
    "Y",
    "ouths sizes 4 - 14  and  S - M",
    "Micr",
    "ofibr",
    "e Sports Short  ",
    "sizes:  youths 4-14 and adult S-M",
    "Softshell Jacket with embr",
    "oider",
    "ed cr",
    "est and detail",
    "sizes: youth sizes 4/6 - 16 ",
    "sizes: adult S- M ",
    "Black Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant with St Francis wording. ",
    "Y",
    "outh sizes 4-14 and ",
    "Adult S-M",
    "Adjustable Hat with new embr",
    "oider",
    "ed logo ",
    "sizes 55-53cm , 57-55cm,  59-57 cm and 61-59cm",
    "Gr",
    "ey Boys Shirt - with embr",
    "oider",
    "ed new cr",
    "est",
    "sizes:  Boys 4-18 ",
    "White Girls Blouse - with embr",
    "oider",
    "ed new cr",
    "est  ",
    "sizes:  Girls 4-16 and Ladies 8-12 ",
    "Boys Shorts - gr",
    "ey with elastic waist",
    " sizes: Boys  4-18",
    "    ",
    " ",
    " ",
    " sizes: Girls 4 -16 and Ladies 8 -12 ",
    "   ",
    " ",
    " ",
    "Skirt - school check with elastic waist to back",
    " sizes: Girls 4 -16 and Ladies 8 -12 ",
    "   ",
    " ",
    " ",
    "Cullote - school check with elastic waist to back",
    "School Sock - gr",
    "ey with school stripes",
    "sizes 9-12, 13-3, 2-8",
    "sizes 9-12, 13-3, 2-8",
    "White sports sock with black and gr",
    "een stripes",
    "s",
    "izes 4-16",
    "Mycr",
    "on tr",
    "ouser",
    " - gr",
    "ey with elastic waist ",
    "Sports Polo ",
    "Sports Short ",
    "Jacket ",
    "T",
    "rackpant ",
    "Hat ",
    "Gr",
    "ey Shirt",
    "Blouse",
    "T",
    "r",
    "ousers",
    "Boys Socks",
    "Sport/ Girls Socks",
    "Shorts",
    "Skirt",
    "Cullotte",
    "59.95",
    "64.95",
    "35.95",
    "16.95",
    "28.95",
    "36.95",
    "39.95",
    "34.95",
    "39.95",
    "39.95",
    "26.95",
    "49.95",
    "  12.95",
    "  12.95",
    " ",
    "Uniform Price List  2022",
    "updated 1/1",
    "1/22",
    "BOYS",
    "GIRLS",
    "SPOR",
    "TS UNIFORM"
  ],
  "modified": "2025-01-23T08:27:10.768Z"
}

public static SFXUniformPricelist2024011223 = {
  "path": "/assets/pdfs/SFX Uniform Pricelist 2024 011223.pdf",
  "newWindow": true,
  "hints": [
    "St Francis Xavier",
    "St Francis Xavier",
    "Gold sports polo - ",
    "sizes 4-14 and S-M",
    "   ",
    "Micr",
    "ofibr",
    "e Sports Short  ",
    "- sizes youths 4-14 and adult S-M",
    "Black Micr",
    "ofibr",
    "e ",
    "T",
    "rackpant ",
    " - sizes 4-14 and S-L",
    "Adjustable Hat -  ",
    "sizes",
    " ",
    "55-53 / 57-55 / 59-57 / 61-59",
    "Gr",
    "een Kindy Polo - ",
    "sizes 2-10",
    "Gr",
    "ey Boys Shirt -  ",
    "sizes  4 -18 ",
    "   ",
    " ",
    " ",
    "Girls ",
    "White Blouse - ",
    " sizes  Girls 4-16 and Ladies 8-12 ",
    "   ",
    " ",
    " ",
    "Boys Shorts - gr",
    "ey elastic waist -",
    " size 4-18 , S-XL",
    "Check Skirt - ",
    " sizes: Girls 4 -16 and Ladies 8 -12 ",
    "   ",
    " ",
    " ",
    "  ",
    " ",
    " ",
    "GirlsCullottes ",
    "- sizes 4-16 and ladies 8-12",
    "Sock - gr",
    "ey with school stripes ",
    "size 9-12, 13-3 and 2-8",
    "White Sock for",
    " Girls and Sport - ",
    "sizes 9-12, 13-3, 2-8",
    "Mycr",
    "on tr",
    "ouser",
    " - gr",
    "ey with elastic waist - ",
    "sizes 4-16 ",
    "Sports Polo ",
    "Sports Short ",
    "T",
    "rackpant ",
    "Hat ",
    "Kindy Polo",
    "Gr",
    "ey Shirt",
    "Blouse",
    "T",
    "r",
    "ousers",
    "Boys Socks",
    "Sport/ Girls Socks",
    "Shorts",
    "Skirt",
    "Cullotte",
    "35.95",
    "16.95",
    "39.95",
    "28.95",
    "36.95",
    "39.95",
    "34.95",
    "39.95",
    "39.95",
    "26.95",
    "49.95",
    "  12.95",
    "  12.95",
    " ",
    "Uniform Price List  2024",
    "updated 1/12/23",
    "BOYS",
    "GIRLS",
    "KINDY",
    "SPOR",
    "TS UNIFORM",
    "Softshell Jacket    ",
    "sizes: 4/6 - 16 ",
    "                                     sizes: adult S- M ",
    "Jacket ",
    " ",
    "59.95",
    "64.95"
  ],
  "modified": "2025-01-23T08:27:10.774Z"
}

public static StLawrence010822 = {
  "path": "/assets/pdfs/St Lawrence 010822.pdf",
  "newWindow": true,
  "hints": [
    "UNIFORM PRICE LIST",
    " 2022",
    "effective 1/8/22",
    "Garment                                   Description                                     Sizes                    Price",
    "Polo Shirt  ",
    "Boys and Girls style",
    "T",
    "eal/Black/gold",
    "W",
    "orn three ( 3) days a week",
    "Socks ",
    "Unisex sock",
    "Black with teal hoop",
    "W",
    "orn everyday ",
    "Hat",
    "Adjustable Bucket Hat (under review )",
    "W",
    "orn everyday ",
    "Shorts",
    "Stretch Microfibre Shorts",
    "Black",
    "Can be worn everyday",
    "Skort ",
    "Pleated skort with inbuilt boy leg short ",
    "Can be worn everyday ",
    "Sport Polo Shirt",
    "Gummer - Red",
    "Thomas - Blue",
    "Kelly - ",
    "Y",
    "ellow",
    "O’Collins - Green ",
    "W",
    "orn two (2) sport days a week",
    "Jacket",
    "Softshell Jacket",
    "Black with embroidery to front and back",
    "Can be worn everyday",
    "Kindy T",
    "Y",
    "ellow / Pink / Sky Blue / Charcoal",
    "Can be worn everyday",
    "4 - 14 childs",
    "S - L  adults",
    "$ 32.95 ",
    "$ 29.95 ",
    "$ 39.95 ",
    "$ 39.95 ",
    "$ 59.95 ",
    "$ 64.95",
    "$ 21.00 ",
    "$ 13.95 ",
    "$ 12.95 ",
    "4 - 14 childs",
    "S - M  adults",
    "4 - 18 girls",
    "4 - 14 childs",
    "S - L  adults",
    "4/6 - 16 childs",
    "S - L  adults",
    "4 -  8  childs",
    "S / M  ",
    "L / XL",
    "  ",
    "sizes:",
    "Childs 9 - 12 ",
    "Childs 13 -3",
    "Adults 2 - 8 ",
    "Adult 8 -1",
    "1"
  ],
  "modified": "2025-01-23T08:27:10.775Z"
}

public static StLawrence010922 = {
  "path": "/assets/pdfs/St Lawrence 010922.pdf",
  "newWindow": true,
  "hints": [
    "UNIFORM PRICE LIST",
    " 2022",
    "effective 1/9/22",
    "Garment                                   Description                                     Sizes                    Price",
    "W",
    "orn three ( 3) days a week",
    "Boys and Girls style",
    "T",
    "eal/Black/gold",
    "Polo Shirt  ",
    "W",
    "orn everyday ",
    "Unisex sock",
    "Black with teal hoop",
    "Socks ",
    "Adjustable Slouch Hat - Black",
    "Adjustable Bucket Hat - Black",
    "Hat",
    "Can be worn everyday",
    "Shorts",
    "Stretch Microfibre Shorts",
    "Black",
    "Pleated skort with inbuilt boy leg short ",
    "Can be worn everyday ",
    "Skort ",
    "W",
    "orn two (2) sport days a week",
    "Gummer - Red",
    "Thomas - Blue",
    "Kelly - ",
    "Y",
    "ellow",
    "O’Collins - Green ",
    "Sport Polo Shirt",
    "Softshell Jacket",
    "Black with embroidery to front and back",
    "Jacket",
    "Can be worn everyday",
    "Y",
    "ellow / Pink / Sky Blue / Charcoal",
    "Kindy T",
    "Can be worn everyday",
    "4 - 14 childs",
    "S - L  adults",
    "$ 32.95 ",
    "$ 29.95 ",
    "$ 39.95 ",
    "$ 39.95 ",
    "$ 59.95 ",
    "$ 64.95",
    "$ 21.00 ",
    "$ 15.95 ",
    "$ 14.95 ",
    "$ 12.95 ",
    "4 - 14 childs",
    "S - M  adults",
    "4 - 18 girls",
    "4 - 14 childs",
    "S - L  adults",
    "4/6 - 16 childs",
    "S - L  adults",
    "4 -  8  childs",
    "4 sizes",
    "4 sizes  ",
    "sizes:",
    "Adults 2 - 8 ",
    "Childs 9 - 12 ",
    "Childs 13 -3",
    "Adult 8 -1",
    "1"
  ],
  "modified": "2025-01-23T08:27:10.776Z"
}

public static StLawrence011223 = {
  "path": "/assets/pdfs/St Lawrence 011223.pdf",
  "newWindow": true,
  "hints": [
    "UNIFORM PRICE LIST",
    " 2024",
    "effective 1/12/23",
    "Garment                                   Description                                     Sizes                    Price",
    "W",
    "orn three ( 3) days a week",
    "Boys and Girls style",
    "T",
    "eal/Black/gold",
    "Polo Shirt  ",
    "Socks ",
    "W",
    "orn everyday ",
    "Unisex sock",
    "Black with teal hoop",
    "Hat",
    "Adjustable Slouch Hat - Black",
    "Adjustable Bucket Hat - Black",
    "Stretch Microfibre Shorts",
    "Black",
    "Can be worn everyday",
    "Shorts",
    "Can be worn everyday ",
    "Skort ",
    "Pleated skort with inbuilt boy leg short ",
    "Sport Polo Shirt",
    "Gummer - Red",
    "Thomas - Blue",
    "Kelly - ",
    "Y",
    "ellow",
    "O’Collins - Green ",
    "W",
    "orn two (2) sport days a week",
    "Softshell Jacket",
    "Black with embroidery to front and back",
    "Can be worn everyday",
    "Jacket",
    "Can be worn everyday",
    "Kindy T",
    "Y",
    "ellow / Pink / Sky Blue / Charcoal",
    "S - L  adults",
    "4 - 14 childs",
    " ",
    "$ 32.95 ",
    "$ 29.95 ",
    "$ 39.95 ",
    "$ 39.95 ",
    "$ 59.95 ",
    "$ 64.95",
    "$ 22.50 ",
    "$ 14.95 ",
    "$ 15.95 ",
    "$ 12.95 ",
    "4 - 14 childs",
    "S - M  adults",
    "4 - 18 girls",
    "S - L  adults",
    "4 - 14 childs",
    "4/6 - 16 childs",
    "S - L  adults",
    "4 -  8  childs",
    "4 sizes",
    "4 sizes  ",
    "Adult 8 -1",
    "1",
    "Adults 2 - 8 ",
    "Childs 9 - 12 ",
    "Childs 13 -3",
    "sizes:"
  ],
  "modified": "2025-01-23T08:27:10.786Z"
}

public static TU2021leaverscataloguev1 = {
  "path": "/assets/pdfs/TU_2021_leavers_catalogue_v1.pdf",
  "newWindow": true,
  "hints": [],
  "modified": "2025-01-23T08:27:10.795Z"
}

public static TotalUniformsAFLWACFLCatalogue2021 = {
  "path": "/assets/pdfs/Total Uniforms AFL WACFL Catalogue 2021.pdf",
  "newWindow": true,
  "hints": [
    "AFL",
    "2022",
    "CATALOGUE",
    "AFL CATALOGUE 2022",
    " ",
    "02",
    "03 ",
    "WHO WE ARE",
    "04 ",
    "HOW TO SIZE GARMENTS",
    "05 ",
    "ELITE JUMPERS MEN",
    "06 ",
    "CLUB JUMPERS MEN",
    "07 ",
    "ELITE JUMPERS WOMEN",
    "08 ",
    "CLUB JUMPERS WOMEN",
    "09 ",
    "SAMPLE DESIGNS",
    "10 ",
    "SPECIAL EVENT JUMPERS",
    "11 ",
    "GAMEDAY APPAREL",
    "12 ",
    "TRAINING GEAR",
    "14 ",
    "OFF FIELD CLOTHING",
    "15 ",
    "BAGS, TOWELS & STUBBYS",
    "16 ",
    "HEADWEAR & SCARFS",
    "18 ",
    "FAQ & ORDER CONFIRMATION",
    "AFL CATALOGUE 2022",
    "03",
    "OUR TEAM",
    "Total Uniforms is a Geraldton owned and operated company who for more than ",
    "20 years have supplied a wide range of sport, work and school apparel across WA and beyond.",
    "Drawing on a wide range of suppliers and partners across the globe we are able to ",
    "fulfill your requirements for game day apparel, merchandise and after game gear.",
    "Our recent partnership with ID Athletic enables us to now offer WACFL approved ",
    "playing apparel, designed locally to your requirements, and with direct consultation with ",
    "our Team.",
    "Our partner, ID Athletic, are a WACFL approved supplier with their own production facilities and ",
    "distribution network giving you peace of mind that your products will be made to ",
    "world class standards. The ID Athletic Team have specialised in the production of ",
    "high quality sportswear since 2012 and have produced more than 1 million garments.",
    "PURPOSE-BUILT MATERIAL",
    "Our standard netball dress fabric is made from breathable eyelet mesh polyester, built for maximum comfort",
    "and durability when playing netball.",
    "QUALITY ASSURANCE",
    "All of our apparel passes through our quality control centre, ensuring that all details are correct and each garment",
    "is finished to an exceptional standard.",
    "SPEED OF DELIVERY",
    "With direct access to our production facility, we can turnaround your order with speed. Discuss the current",
    "delivery timeframes with your Partnership Manager that are specific to the garments in your order. ",
    "FINISHED PRODUCT",
    "Every product is presented within it’s own individual bag with the size label visible, making distribution",
    "to your players quick and easy.",
    "OUR DIFFERENCE",
    "04",
    "GET IN TOUCH",
    "To speak directly to our WACFL expert Nick Knight contact:",
    "Email: Nick.Knight@totaluniforms.com.au",
    "Phone: (08) 99653101",
    "Mobile: 0448 226 524",
    "Alternatively, you can visit us at:",
    "Cnr Northwest Coastal Highway and Jensen Street Geraldton",
    "The most important part of sizing up your",
    "ID Athletic garments is that our size charts refer",
    "to the garment itself, not the wearer.",
    "So, when you’re figuring out the best size to",
    "order, we recommend measuring against an",
    "existing garment that is the correct fit, and",
    "comparing those measurements to the ones i",
    "our size charts.",
    "The main two measurements are half-chest",
    "(measurement A on the diagram) which is from",
    "armpit-to-armpit, and the length (measurement",
    "B on the diagram, which is from shoulder to hem.",
    "Alternatively, you can borrow one of our sizing",
    "kits from our Osborne Park o\u001dce at no cost.",
    "AFL CATALOGUE 2022",
    "05",
    "ELITE MENS FOOTBALL JUMPER SIZE CHART",
    "Measurements are half chest of the garment, measured across the front from armpit to armpit.",
    "All measurements in centimetres (cm).",
    "4Y",
    " ",
    "6Y",
    " ",
    "8Y",
    " ",
    "10Y",
    " ",
    "12Y",
    " ",
    "14Y",
    " ",
    "XS",
    " ",
    "S",
    " ",
    "M",
    " ",
    "L",
    "31",
    " ",
    "33.5",
    " ",
    "36",
    " ",
    "38.5",
    " ",
    "41",
    " ",
    "43.5",
    " ",
    "46",
    " ",
    "48.5",
    " ",
    "51",
    " ",
    "53.5",
    "HALF-CHEST (CUFF TO CUFF)",
    "XL",
    " ",
    "2XL",
    " ",
    "3XL",
    "56",
    " ",
    "58.5",
    " ",
    "61",
    "4XL",
    "63.5",
    "• 280gsm sportsmesh",
    "• breathable & durable",
    "• sublimated decoration",
    "• tapered fit for optimal",
    "   performance",
    "• kids & toddlers options",
    "• GPS Pouch optional",
    "• Four collar choices",
    "AFL CATALOGUE 2022",
    "4Y",
    " ",
    "6Y",
    " ",
    "8Y",
    " ",
    "10Y",
    " ",
    "12Y",
    " ",
    "14Y",
    " ",
    "XS",
    " ",
    "S",
    " ",
    "M",
    " ",
    "L",
    "48.5",
    " ",
    "51",
    " ",
    "53.5",
    " ",
    "56",
    " ",
    "59.5",
    " ",
    "63",
    " ",
    "67",
    " ",
    "69.5",
    " ",
    "72",
    " ",
    "74.5",
    "FRONT LENGTH (COLLAR TO HEM)",
    "XL",
    " ",
    "2XL",
    " ",
    "3XL",
    "77",
    " ",
    "79.8",
    " ",
    "82",
    "4XL",
    "85",
    "ELITE ",
    "JUMPERS MEN",
    "06",
    "CP563 PRO FOOTBALL JUMPER SIZE CHART",
    "Measurements are half chest of the garment, measured across the front from armpit to armpit.",
    "All measurements in centimetres (cm).",
    "4Y",
    " ",
    "6Y",
    " ",
    "8Y",
    " ",
    "10Y",
    " ",
    "12Y",
    " ",
    "14Y",
    " ",
    "XS",
    " ",
    "S",
    " ",
    "M",
    " ",
    "L",
    "31",
    " ",
    "33.5",
    " ",
    "36",
    " ",
    "38.5",
    " ",
    "41",
    " ",
    "43.5",
    " ",
    "46",
    " ",
    "48.5",
    " ",
    "51",
    " ",
    "53.5",
    "HALF-CHEST (CUFF TO CUFF)",
    "XL",
    " ",
    "2XL",
    " ",
    "3XL",
    "56",
    " ",
    "58.5",
    " ",
    "61",
    "4XL",
    "63.5",
    "• 270gsm sportsmesh",
    "• breathable & durable",
    "• sublimated decoration",
    "• traditional fit for optimal",
    "   performance",
    "• kids & toddlers options",
    "AFL CATALOGUE 2022",
    "4Y",
    " ",
    "6Y",
    " ",
    "8Y",
    " ",
    "10Y",
    " ",
    "12Y",
    " ",
    "14Y",
    " ",
    "XS",
    " ",
    "S",
    " ",
    "M",
    " ",
    "L",
    "48.5",
    " ",
    "51",
    " ",
    "53.5",
    " ",
    "56",
    " ",
    "59.5",
    " ",
    "63",
    " ",
    "67",
    " ",
    "69.5",
    " ",
    "72",
    " ",
    "74.5",
    "FRONT LENGTH (COLLAR TO HEM)",
    "XL",
    " ",
    "2XL",
    " ",
    "3XL",
    "77",
    " ",
    "79.8",
    " ",
    "82",
    "4XL",
    "85",
    "CLUB ",
    "JUMPERS MEN",
    "07",
    "ELITE WOMENS FOOTBALL JUMPER SIZE CHART",
    "Measurements are chest of the garment, measured across the front and back from armpit to armpit.",
    "All measurements in centimetres (cm).",
    "2",
    " ",
    "4",
    " ",
    "6",
    " ",
    "8",
    " ",
    "10",
    " ",
    "12",
    " ",
    "14",
    " ",
    "16",
    " ",
    "18",
    " ",
    "20",
    "55",
    " ",
    "60",
    " ",
    "65",
    " ",
    "70",
    " ",
    "75",
    " ",
    "80",
    " ",
    "85",
    " ",
    "90",
    " ",
    "95",
    " ",
    "100",
    "Chest Circumference",
    "22",
    " ",
    "24",
    " ",
    "26",
    "105",
    " ",
    "110",
    " ",
    "115",
    "28",
    "120",
    "30",
    "125",
    "• 280gsm sportsmesh",
    "• breathable & durable",
    "• sublimated decoration",
    "• tapered fit for optimal",
    "   performance",
    "• kids & toddlers options",
    "• GPS Pouch optional",
    "• Four collar choices",
    "AFL CATALOGUE 2022",
    "FRONT LENGTH (COLLAR TO HEM)",
    "2",
    " ",
    "4",
    " ",
    "6",
    " ",
    "8",
    " ",
    "10",
    " ",
    "12",
    " ",
    "14",
    " ",
    "16",
    " ",
    "18",
    " ",
    "20",
    "43",
    " ",
    "46",
    " ",
    "49",
    " ",
    "52",
    " ",
    "56",
    " ",
    "59.5",
    " ",
    "63",
    " ",
    "67",
    " ",
    "69.5",
    " ",
    "70",
    "22",
    " ",
    "24",
    " ",
    "26",
    "74",
    " ",
    "76",
    " ",
    "78",
    "28",
    "80",
    "30",
    "82",
    "ELITE ",
    "JUMPERS WOMEN",
    "08",
    "CP563 PRO FOOTBALL JUMPER SIZE CHART",
    "Measurements are chest of the garment, measured across the front and back from armpit to armpit.",
    "All measurements in centimetres (cm).",
    "2",
    " ",
    "4",
    " ",
    "6",
    " ",
    "8",
    " ",
    "10",
    " ",
    "12",
    " ",
    "14",
    " ",
    "16",
    " ",
    "18",
    " ",
    "20",
    "55",
    " ",
    "60",
    " ",
    "65",
    " ",
    "70",
    " ",
    "75",
    " ",
    "80",
    " ",
    "85",
    " ",
    "90",
    " ",
    "95",
    " ",
    "100",
    "Chest Circumference",
    "22",
    " ",
    "24",
    " ",
    "26",
    "105",
    " ",
    "110",
    " ",
    "115",
    "28",
    "120",
    "30",
    "125",
    "• 270gsm sportsmesh",
    "• breathable & durable",
    "• sublimated decoration",
    "• traditional fit for optimal",
    "   performance",
    "• kids & toddlers options",
    "AFL CATALOGUE 2022",
    "FRONT LENGTH (COLLAR TO HEM)",
    "2",
    " ",
    "4",
    " ",
    "6",
    " ",
    "8",
    " ",
    "10",
    " ",
    "12",
    " ",
    "14",
    " ",
    "16",
    " ",
    "18",
    " ",
    "20",
    "43",
    " ",
    "46",
    " ",
    "49",
    " ",
    "52",
    " ",
    "56",
    " ",
    "59.5",
    " ",
    "63",
    " ",
    "67",
    " ",
    "69.5",
    " ",
    "70",
    "22",
    " ",
    "24",
    " ",
    "26",
    "74",
    " ",
    "76",
    " ",
    "78",
    "28",
    "80",
    "30",
    "82",
    "CLUB ",
    "JUMPERS WOMEN",
    "09",
    "DESIGN A",
    " ",
    "DESIGN B",
    " ",
    "DESIGN C",
    "DESIGN D",
    " ",
    "DESIGN E",
    " ",
    "DESIGN F",
    "DESIGN G",
    " ",
    "DESIGN H",
    " ",
    "DESIGN I",
    "DESIGN J",
    " ",
    "DESIGN K",
    " ",
    "DESIGN L",
    "You can choose from a wide range of  standard designs or create your own.",
    "We will work with you to get the look you want and easily add your sponsors logo's.",
    "Your designs can be simply any easily transferred to any other garment in our range.",
    "AFL CATALOGUE 2022",
    "SAMPLE ",
    "DESIGNS",
    "SPECIAL EVENTS ",
    "JUMPERS",
    "10",
    "AFL CATALOGUE 2022",
    "BIG GAME PLANNED?",
    "We have the ability to bring your marqueue games to the next level with our special events ",
    "game jumpers for men and women. Chat to our design team who can bring your ideas to life ",
    "for milestone games, ANZAC Day, indigenous round, charity games or any other occasion.",
    "11",
    "MEN’S CLUB SHORTS",
    "Created for high performance and comfort ",
    "in game. Sublimated design allows these to be ",
    "completely customisable with your team’s ",
    "colours and design.",
    "AFL CATALOGUE 2022",
    "GAMEDAY ",
    "APPAREL",
    "WOMEN’S CLUB SHORTS",
    "Created for high performance and comfort ",
    "in game. Sublimated design allows these to be ",
    "completely customisable with your team’s ",
    "colours and design.",
    "SOCKS",
    "Our sublimated AFL socks can feature",
    "full colour detailed artwork, which is printed",
    "into the sock rather than knitted into the sock",
    "during production. These come in ankle, quarter,",
    "mid & crew length. Classic fold over socks ",
    "are also available.",
    "12",
    "MENS & WOMENS SINGLETS",
    "Our lightweight training singlets are ideal for intense preseason conditions. Female and male",
    "fits are available to facilitate high performance and comfort. Choose from our designs or chat ",
    "to our design team who can work to your vision.   ",
    "AFL CATALOGUE 2022",
    "TRAINING ",
    "GEAR",
    "TRAINING TEES",
    "Made from our versatile and lightweight ",
    "polyester fabric, these garments",
    "are fully sublimated and can be completed",
    "customised to match your team’s colours",
    "and design. ",
    "13",
    "WOMEN’S SHORTS",
    "Our off field shorts have been created with training ",
    "conditions in mind. Made with a fast drying fabric ",
    "with elasticated waist and drawcord.",
    "MENS SHORTS",
    "Our off field shorts have been created with ",
    "training conditions in mind. Made with a fast ",
    "drying fabric with elasticated waist and ",
    "drawcord.",
    "CHASING SOMETHING SPECIFIC?",
    "We also have a wide range of other products and patterns available, so if what you’re looking",
    "for isn’t here, please speak with our friendly team and so that we can meet your football",
    "club’s apparel needs.",
    "AFL CATALOGUE 2022",
    "TRAINING ",
    "GEAR",
    "14",
    "AFL CATALOGUE 2022",
    "CLUB POLOS",
    "Our polos are fully sublimated on purpose-built",
    "polyester, meaning they are fully customisable",
    "with colours, designs and logos. We have both",
    "unisex and womens’ cuts available, with a",
    "foldover collar and three button enclosure.",
    "HOODIES",
    "We have a wide-range of fleecy hoodies",
    "available, with a wide range of colours",
    "and options for both pullover and zip-up.",
    "Our hoodies feature screenprinted",
    "decoration, with make for a professional,",
    "long-lasting finish.",
    "GOT AN EXISTING DESIGN?",
    "Our in-house graphic designers can match your existing designs to ensure a seamless",
    "experience when ordering. Feel free to send through photos of your existing",
    "range and our team can help you out with the design.",
    "OFF FIELD ",
    "CLOTHING",
    "15",
    " ",
    "AFL CATALOGUE 2022",
    "RUGBY POLOS",
    "The rugby jersey is customisable with knitted ",
    "stripe panels, then badges made from either ",
    "nylon or felt are embroidered onto the polo.",
    "Cut & Sew Hoodies",
    "We have a wide range of caps available from a",
    "number of different suppliers to meet your",
    "club’s needs. Our caps feature embroidered",
    "decoration for a professional, long-lasting finish.",
    "JACKETS",
    "We have a wide range of jackets available from a",
    "number of different suppliers to meet your",
    "club’s needs. Our stock jackets feature ",
    "embroidered or screen printed decoration for a",
    "professional, long-lasting finish.",
    "OFF FIELD ",
    "CLOTHING",
    "16",
    "SPORTS BAGS",
    "Our sportsbags come in a range of colours",
    "and feature a double-zipped main",
    "compartment and elasticised mesh side",
    "pockets, with studded feet for durability and",
    "an embroidered club crest decoration.",
    "TOWELS",
    "Our high-quality sports towels are manufactured",
    "from 420gsm cotton terry towelling, with a large",
    "surface area and smooth area for embroiderered",
    "decoration, and come in a range of colours.",
    "AFL CATALOGUE 2022",
    "STUBBY HOLDERS",
    "Our high-quality stubby holders are fully sublimated",
    "from neoprene. This allows for complete customisation ",
    "including club logos and colours.",
    "BAGS, TOWELS & ",
    "STUBBYS",
    "CAPS",
    "We have a wide range of caps available from a",
    "number of different suppliers to meet your",
    "club’s needs. Our caps feature embroidered",
    "decoration for a professional, long-lasting finish.",
    "17",
    " ",
    "AFL CATALOGUE 2022",
    "CUSTOM BEANIES",
    "Our beanies feature custom embroidered decoration",
    "along with your team name included into the knit of ",
    "the fabric.",
    "CUSTOM SCARVES",
    "Our scarves feature custom decoration included ",
    "into the knit of the fabric.",
    "HEADWEAR & ",
    "SCARVES",
    "18",
    "HOW DO I BEST CARE FOR MY FOOTBALL APPAREL?",
    "Follow the care instructions outlined within the tags on your garment. In addition, do not iron over heatsealed or",
    "screenprinted names & numbers.",
    "HOW MANY ITEMS CAN I ORDER",
    "Dependent on the product, most of our apparel comes with no minimum orders, however pricing may vary on",
    "stock or custom cut-and-sew products.",
    "CAN I PLACE A SECOND ORDER & HOW WILL IT BE PRICED?",
    "Yes, you can, and if it is a sublimated product with no changes, you won’t have to pay the setup fee. Please speak",
    "with our team and we will be able to confirm pricing for any additional orders.",
    "HOW ARE THE FREIGHT CHARGES CALCULATED?",
    "Speak to our team about freight charges, as these can vary depending on the size of the order and delivery",
    "location. Otherwise, you can opt to collect your order from our convienient Osborne Park location.",
    "CAN I DESIGN MY OWN FOOTBALL APPAREL?",
    "Absolutely - if you’re feeling creative or have talented artists at the club,you are welcome to come up with the",
    "design and our team can help you turn the creation into reality.",
    "ORDER CONFIRMATION",
    "Placing an order with us is easy - simply get in touch with our team and we will guide you",
    "through the ordering process. Our design team will provide you with a bespoke design in your",
    "school’s branding and colours. Every design can be customised to suit your requirements.",
    "We value high quality service, and we will ensure that you are provided with honest, consistent",
    "lead-times and we maintain communication throughout the production and delivery process.",
    "Lead times may vary dependent on the garments ordered, and all orders are signed off by the",
    "customer for final approval before production commences.",
    "AFL CATALOGUE 2022",
    "Nick.Knight@totaluniforms.com.au",
    "phone: (08) 99653101",
    "mobile: 0448226524",
    "PREFERRED",
    "ONFIELD",
    "SUPPLIER"
  ],
  "modified": "2025-01-23T08:27:10.801Z"
}

public static WAGGRAKINE011020 = {
  "path": "/assets/pdfs/WAGGRAKINE 011020.pdf",
  "newWindow": true,
  "hints": [
    "Jumpers",
    "Royal Blue Zip Windcheater ",
    " ",
    " Childs size 4-18 ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 36.95   ",
    " ",
    " ",
    "   ",
    " W",
    "AG2503RB",
    "Royal Blue Skort   ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Girls sizes 4-16 ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 26.95     ",
    "7LPS3",
    "Royal Blue Microfibre Short ",
    " ",
    "Childs sizes  6-14 and ",
    "Adult S-M ",
    " ",
    " ",
    " ",
    "$ 24.95     ",
    "SS29K/SS29",
    "Y",
    "ear 1-5 Polo ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Childs sizes 3-14 and adult S-M ",
    " ",
    " ",
    " ",
    "$ 29.95     ",
    "W",
    "AGG1-5P",
    " ",
    "6",
    "Shorts and Skorts",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "effective 1/1",
    "1/20",
    "Kindy / Pre- Primary Polo ",
    " ",
    " ",
    " ",
    "Childs sizes 3-8  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 29.95     ",
    "W",
    "AGGKP",
    " ",
    "Faction Polo Shirts",
    "Red /Green/ ",
    "Y",
    "ellow/ Blue ",
    " ",
    " ",
    " ",
    "Childs sizes 4-14 and adult S- L",
    "   ",
    " ",
    " ",
    " ",
    "$ 25.95 ",
    " ",
    " ",
    " ",
    "     ",
    "W",
    "AGGSP",
    "Polo Shirts",
    "Y",
    "ear 6 Polo ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Childs sizes 8-14 and adult M-L ",
    " ",
    " ",
    " ",
    "$ 29.95     ",
    "W",
    "AGGY6P",
    "Royal/Red, Royal/Emerald, Royal/ Gold, Royal/Sky ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 15.95   ",
    " ",
    " ",
    "   BUCHA",
    "TREV",
    " ",
    "Hats",
    "Reversible Wide Brim Hat ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Uniform Price List 2021",
    "Kindy/ Pre-Primary",
    " ",
    " ",
    "Y",
    "ear 1-5",
    " ",
    " ",
    "Y",
    "ear 6"
  ],
  "modified": "2025-01-23T08:27:10.804Z"
}

public static WAGGRAKINE141122 = {
  "path": "/assets/pdfs/WAGGRAKINE 141122.pdf",
  "newWindow": true,
  "hints": [
    " ",
    "Polo Shirts",
    "Reversible Wide Brim Hat ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Royal/Red, Royal/Emerald, Royal/ Gold, Royal/Sky ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 15.95   ",
    " ",
    " ",
    "   BUCHA",
    "TREV",
    "Kindy / Pre- Primary Polo ",
    " ",
    " ",
    " ",
    "Childs sizes 3-8  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 32.95     ",
    "W",
    "AGGKP",
    " ",
    "Royal Blue Microfibre Short ",
    " ",
    "Childs sizes  6-14 and ",
    "Adult S-M ",
    " ",
    " ",
    " ",
    "$ 24.95     ",
    "SS29K/SS29",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "effective 1/1",
    "1/22",
    "Shorts and Skorts",
    "Royal Blue Zip Windcheater ",
    " ",
    " Childs size 4-18 ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 36.95   ",
    " ",
    " ",
    "   ",
    " W",
    "AG2503RB",
    "Y",
    "ear 1-5 Polo ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Childs sizes 3-14 and adult S-M ",
    " ",
    " ",
    " ",
    "$ 32.95     ",
    "W",
    "AGG1-5P",
    " ",
    "Y",
    "ear 6 Polo ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Childs sizes 8-14 and adult M-L ",
    " ",
    " ",
    " ",
    "$ 32.95     ",
    "W",
    "AGGY6P",
    "Jumpers",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Faction Polo Shirts",
    "Red /Green/ ",
    "Y",
    "ellow/ Blue ",
    " ",
    " ",
    " ",
    "Childs sizes 4-14 and ",
    "Adult S- L",
    "   ",
    " ",
    " ",
    "$ 25.95     ",
    "W",
    "AGGSP",
    "Royal Blue Skort   ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Girls sizes 4-16 ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 28.95     ",
    "7LPS3",
    "Hats",
    "Uniform Price List 2023",
    "Kindy/ Pre-Primary",
    " ",
    " ",
    "Y",
    "ear 1-5",
    " ",
    " ",
    "Y",
    "ear 6"
  ],
  "modified": "2025-01-23T08:27:10.806Z"
}

public static WAGGRAKINEprimary2024011223 = {
  "path": "/assets/pdfs/WAGGRAKINE primary 2024 011223.pdf",
  "newWindow": true,
  "hints": [
    "Y",
    "ear 1-5 Polo ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Childs sizes 3-14 and adult S-M ",
    " ",
    " ",
    " ",
    "$ 32.95     ",
    "W",
    "AGG1-5P",
    " ",
    "Royal Blue Skort   ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Girls sizes 4-16 ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 28.95     ",
    "7LPS3",
    "Shorts and Skorts",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "effective 1/12/23",
    "Polo Shirts",
    "Kindy / Pre- Primary Polo ",
    " ",
    " ",
    " ",
    "Childs sizes 3-8  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 32.95     ",
    "W",
    "AGGKP",
    " ",
    "Y",
    "ear 6 Polo ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Childs sizes 8-14 and adult M-L ",
    " ",
    " ",
    " ",
    "$ 32.95     ",
    "W",
    "AGGY6P",
    "Faction Polo Shirts",
    "Red /Green/ ",
    "Y",
    "ellow/ Blue ",
    " ",
    " ",
    " ",
    "Childs sizes 4-14 and ",
    "Adult S- L",
    "   ",
    " ",
    " ",
    "$ 25.95     ",
    "W",
    "AGGSP",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Royal Blue Microfibre Short ",
    " ",
    "Childs sizes  6-14 and ",
    "Adult S-M ",
    " ",
    " ",
    " ",
    "$ 24.95     ",
    "SS29K/SS29",
    "Royal Blue Basketball Short ",
    " ",
    "Childs sizes  6-14 and ",
    "Adult S-M             $ 27.50     ",
    "SS29K/SS29",
    "Jumpers",
    "Royal Blue Zip Windcheater ",
    " ",
    " Childs size 4-18 ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 36.95   ",
    " ",
    " ",
    "   ",
    " W",
    "AG2503RB",
    "Reversible Wide Brim Hat ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "Hats",
    "Royal/Red, Royal/Emerald, Royal/ Gold, Royal/Sky ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 19.95   ",
    " ",
    " ",
    "   BUCHA",
    "TREV",
    " ",
    "Uniform Price List 2024",
    "Kindy/ Pre-Primary",
    " ",
    " ",
    "Y",
    "ear 1-5",
    " ",
    " ",
    "Y",
    "ear 6"
  ],
  "modified": "2025-01-23T08:27:10.815Z"
}

public static WandinaUniformCatalogue2023 = {
  "path": "/assets/pdfs/Wandina Uniform Catalogue 2023.pdf",
  "newWindow": true,
  "hints": [
    "School Uniform 2023",
    "Garment                                   Description                                     Sizes                    Price",
    "Day Polo Shirt  ",
    "Short sleeve cotton backed microfibre",
    "Aqua /navy/ teal with white piping",
    "W",
    "orn everyday except sport days",
    "Kindy / Pre-school / Primary School",
    "Can wear everyday ",
    "Winter",
    " Jacket ",
    "Kindy / Pre-school / Primary School",
    "100% Microfibre polyester  ",
    "Aqua /Navy/ teal/white",
    "Fleece Jumper",
    " ",
    " Cotton / Polyester   - Navy Blue",
    "Can wear everyday ",
    "Kindy / Pre-school / Primary School",
    "Hat- reversible",
    "Kindy / Pre-school / Primary School",
    "W",
    "orn everyday ",
    "Navy one side sports house colour other side ",
    "100% Microfibre polyester",
    "Kindy / Pre-school / Primary School",
    "Shorts",
    "JB 100% polyester sports shorts - Navy Blue",
    " Dress ",
    "Short sleeve zip front dress ",
    "Action back with rear belt",
    "Navy/white/teal with teal piping",
    "Can be worn everyday except sport days",
    "Kindy / Pre-school / Primary School",
    "Pleated skort with inbuilt boy leg short - Navy blue ",
    "Kindy / Pre-school / Primary School",
    "Can be worn everyday with polo ",
    "Skort ",
    "Royal/Navy - Bobtail, Emerald/Navy- Frog",
    "Sport Polo Shirt",
    "Red/Navy-Echidna , Gold/Navy-Pelican",
    "Primary School",
    "Short sleeve cotton back microfibre",
    "* Optional for Kindy/Pre-primary at carnival",
    "W",
    "orn on sport days",
    "S - M  adults",
    "4 - 14 youths",
    " ",
    "$ 32.95 ",
    "$ 25.95 ",
    "$ 32.95",
    "$ 49.95 ",
    "$ 32.95 ",
    "$ 17.95 ",
    "$ 49.95 ",
    "$ 39.95 ",
    "4 - 14 youths",
    "S - M  adults",
    "4 - 14 girls",
    "4 - 14 youths",
    "S - M  adults",
    "L/XL",
    "   ( 61 - 57cm)",
    "S / M  ( 58 - 54cm ) ",
    "4 - 14 girls",
    "S - M  adults",
    "4 - 14 youths",
    "S - L  adults",
    "4 - 14 youths",
    "Ef",
    "fective 1/9/2022"
  ],
  "modified": "2025-01-23T08:27:10.817Z"
}

public static WandinaUniformCatalogue2024 = {
  "path": "/assets/pdfs/Wandina Uniform Catalogue 2024.pdf",
  "newWindow": true,
  "hints": [
    "School Uniform 2024",
    "Garment                                   Description                                     Sizes                    Price",
    "W",
    "orn everyday except sport days",
    "Aqua /navy/ teal with white piping",
    "Day Polo Shirt  ",
    "Kindy / Pre-school / Primary School",
    "Short sleeve cotton backed microfibre",
    "Winter",
    " Jacket ",
    "100% Microfibre polyester  ",
    "Aqua /Navy/ teal/white",
    "Kindy / Pre-school / Primary School",
    "Can wear everyday ",
    "Kindy / Pre-school / Primary School",
    "Can wear everyday ",
    "Fleece Jumper",
    " ",
    " Cotton / Polyester   - Navy Blue",
    "Navy one side sports house colour other side ",
    "Hat- reversible",
    "100% Microfibre polyester",
    "Kindy / Pre-school / Primary School",
    "W",
    "orn everyday ",
    "Shorts",
    "JB 100% polyester sports shorts - Navy Blue",
    "Kindy / Pre-school / Primary School",
    "Navy/white/teal with teal piping",
    "Can be worn everyday except sport days",
    "Kindy / Pre-school / Primary School",
    "Short sleeve zip front dress ",
    "Action back with rear belt",
    " Dress ",
    "Can be worn everyday with polo ",
    "Skort ",
    "Pleated skort with inbuilt boy leg short - Navy blue ",
    "Kindy / Pre-school / Primary School",
    "Short sleeve cotton back microfibre",
    "Red/Navy-Echidna , Gold/Navy-Pelican",
    "Royal/Navy - Bobtail, Emerald/Navy- Frog",
    "Sport Polo Shirt",
    "W",
    "orn on sport days",
    "Primary School",
    "* Optional for Kindy/Pre-primary at carnival",
    "4 - 14 youths",
    "S - M  adults",
    "$ 32.95 ",
    "$ 25.95 ",
    "$ 34.95",
    "$ 49.95 ",
    "$ 34.95 ",
    "$ 17.95 ",
    "$ 54.95 ",
    "$ 39.95 ",
    "4 - 14 youths",
    "S - M  adults",
    "4 - 14 girls",
    "S - M  adults",
    "4 - 14 youths",
    "S / M  ( 58 - 54cm ) ",
    "L/XL",
    "   ( 61 - 57cm)",
    "4 - 14 girls",
    "4 - 14 youths",
    "S - M  adults",
    "S - L  adults",
    "4 - 14 youths",
    "Ef",
    "fective 20/10/2023"
  ],
  "modified": "2025-01-23T08:27:10.828Z"
}

public static YalgooPS20232024 = {
  "path": "/assets/pdfs/Yalgoo PS 2023 2024.pdf",
  "newWindow": true,
  "hints": [
    "Y",
    "algoo Primary School",
    "Uniform Price List ",
    " ",
    "Effective 1st December 2023",
    "4014 Black / ",
    "T",
    "eal Cap with ",
    "Y",
    "algoo wording ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 14.50",
    "7NSS ",
    "Black sports shorts sizes 6-14 and S -M ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 25.95",
    "4247 Black Floppy Hat with ",
    "Y",
    "algoo wording ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 19.95",
    "FL01 Black Fleecy V neck Jumper with  logo ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 35.95",
    "W3311 T",
    "asman Polo size 4-16  only with Logo  ",
    " ",
    " ",
    " ",
    " ",
    " ",
    " ",
    "$ 36.95",
    "IMPORT",
    "ANT",
    ":  ",
    "These items are not stock items and need to be ordered",
    " ",
    " ",
    "and embroidered for you ",
    "AFTER payment is made.",
    "Our team will notify you by text or phone call when ",
    " ",
    " ",
    "your  uniforms are ready to collect",
    ".",
    "PRIMAR",
    "Y",
    " SCHOOL",
    "Y",
    "ALGOO"
  ],
  "modified": "2025-01-23T08:27:10.832Z"
}

};